import React, { useState } from 'react';
import Card from '../../common/card/Card';
import dash3 from '../../common/assests/dasimg3.png';
import dash5 from '../../common/assests/dasimg5.png';
import './TotalSale.css';
import { Card as MuiCard, Box, styled } from '@mui/material';
import { WeekGraph } from './WeekGraph';
import MonthGraph from './MonthGraph';
import YearGraph from './YearGraph';

const CustomCard = styled(MuiCard)(({ theme }) => ({
  borderRadius: '24px',
  backgroundColor: '#FFF',
  boxShadow: '3px 2px 20px 0px rgba(0, 0, 0, 0.04)',
  height: '250px',
  flexShrink: 0,
  marginBottom: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '18px',
  fontWeight: 'bold',
  color: '#362B24',
}));

const TotalSales = () => {
  const [activeComponent, setActiveComponent] = useState(0);

  const handleToggle = (index) => {
    setActiveComponent(index);
  };

  const weekData = [
    { text: 'Week Data 1' },
    { text: 'Week Data 2' },
  ];

  const monthData = [
    { text: 'Month Data 1' },
    { text: 'Month Data 2' },
  ];

  const yearData = [
    { text: 'Year Data 1' },
    { text: 'Year Data 2' },
  ];

  const data = [
    { img: dash3, text1: "20k", text2: 'Total Orders', text3: '+2.3' },
    { img: dash5, text1: "20k", text2: 'Total Earnings', text3: '-2.4' },
    { img: dash5, text1: "20k", text2: 'Total Earnings', text3: '-2.4' },
  ];

  const getDataForPeriod = () => {
    switch (activeComponent) {
      case 0:
        return weekData;
      case 1:
        return monthData;
      case 2:
        return yearData;
      default:
        return [];
    }
  };

  return (
    <div className="row g-3 custom-row-1 mt-4">
      {data.map((item, index) => (
        <div key={index} className="col-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
          <Card data={item} />
        </div>
      ))}

      <div className="total-sales-container d-flex justify-content-between align-items-center flex-wrap gap-2 mt-5">
        <div style={{ color: "#362B24", fontSize: '22px', fontWeight: '500' }}>
          Total Sales
        </div>
        <div className="button-box">
          <div
            id="btn"
            style={{ 
              left: `${activeComponent * 33.33}%`, // Adjust based on 3 buttons
              width: `31.33%` // Each button takes up one-third of the container
            }}
          ></div>
          <button
            className={`toggle-btn ${activeComponent === 0 ? 'active' : ''}`}
            onClick={() => handleToggle(0)}
          >
            Week
          </button>
          <button
            className={`toggle-btn ${activeComponent === 1 ? 'active' : ''}`}
            onClick={() => handleToggle(1)}
          >
            Month
          </button>
          <button
            className={`toggle-btn ${activeComponent === 2 ? 'active' : ''}`}
            onClick={() => handleToggle(2)}
          >
            Year
          </button>
        </div>
      </div>



      {activeComponent===0 && <WeekGraph/>}
      {activeComponent===1 && <MonthGraph/>}
      {activeComponent===2 && <YearGraph/>}
    </div>
  );
};

export default TotalSales;
