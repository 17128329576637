import React, { useState } from 'react';
import { Avatar, IconButton, Box } from '@mui/material';
import UploaderIcon from '../assests/imguploader.png';
import Gallery from '../assests/gallery.png';

function ImageUploader({ data = 'Upload Image' }) {
  const [uploadedImage, setUploadedImage] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box sx={{ textAlign: 'center', mt: 4 }}>
      <Box sx={{ position: 'relative', display: 'inline-block' }}>
        <Avatar
          sx={{
            width: 120,
            height: 120,
            backgroundColor: '#FFF',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            overflow: 'hidden',
          }}
        >
          {uploadedImage ? (
            <img
              src={uploadedImage}
              alt="Uploaded"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          ) : (
            <img
              src={Gallery}
              alt="Upload Icon"
              style={{
                width: '40px',
                height: '40px',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          )}
        </Avatar>
        <label htmlFor="image-upload">
          <IconButton
            component="span"
            sx={{
              position: 'absolute',
              bottom: 20,
              right: 0,
              backgroundColor: '#FFF',
              borderRadius: '50%',
            }}
          >
            <img src={UploaderIcon} alt='Upload Icon' style={{ width: '24px', height: '24px' }} />
          </IconButton>
        </label>
        <input
          id="image-upload"
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={handleImageUpload}
        />
      </Box>
      <Box sx={{ mt: 1, color: '#8d8d8d', fontSize: '14px', fontFamily: 'Heebo' }}>
        {data}
      </Box>
    </Box>
  );
}

export default ImageUploader;
