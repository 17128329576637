import React, { useEffect, useRef, useState } from "react";
import backImg from "../../../common/assests/backImg.png";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import TestingImg from "../../../common/assests/gridimg3.png";
import Adminimg from "../../../common/assests/adminimg.png";
import EditIcon from "../../../common/assests/editicon.png";
import Message from "../../../common/assests/message.svg";
import More from "../../../common/assests/more.svg";
import DeleteIcon from "../../../common/assests/deleticon.png";
import PeopleIcon from "../../../common/assests/people.svg";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Image } from "@mui/icons-material";
import ForumModal from "../ForumModal";
import Clock from "../../../common/assests/clock.svg";

const ModrationDetail = ({ }) => {
  const data = [
    {
      id: 1,
      imgSrc: Adminimg,
      name: "Linda Grodan",
      date: "15 Dec, 2023",
      description:
        "Lorem Ipsum is simply dummy text of the printing and type setting to industry has been the industry that can standard dummy text ever since the, when an also dummy text of the printing and type setting to industry has been the industry that can that people do.",
      likes: 24,
      comments: 24,
    },
    {
      id: 2,
      imgSrc: Adminimg,
      name: "Linda Grodan",
      date: "15 Dec, 2023",
      description:
        "Lorem Ipsum is simply dummy text of the printing and type setting to industry has been the industry that can standard dummy text ever since the, when an also dummy text of the printing and type setting to industry has been the industry that can that people do.",
      likes: 24,
      comments: 24,
    },
  ];
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const location = useLocation();
  const { event } = location.state || {};

  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });
  const [events, setEvents] = useState([
    {
      id: 1,
      eventImg: TestingImg,
      title: "Form Title",
      people: PeopleIcon,

      description:
        "Lorem Ipsum is simply dummy text of the printing and type setting simply dummy text of the printing and type text of the printing and type Lorem torp that can also is simply text of the printing and type setting simply dummy text of the printing and type text of the printing and type text of the printing and type setting text of the printing and type text of the that can on people we will not moving forward going pertetion on that can on itill united to people on demand on your time yet on demand your people ground that can on people we will not moving forward going pertetion on that can on itill united to people on demand on your time yet on demand your people that can on people we will not moving forward going pertetion on that can on itill united to people on demand on your time yet on demand that can on people we will not moving forward going pertetion on that can on itill united to people on demand on your time yet on demand your people pertetion on that can on itill united to people on demand on your time yet on demand your people ground that can on people we will not moving forward going pertetion on that can on itill united to on that gone wrong .",
    },
    {
      id: 2,
      eventImg: TestingImg,
      title: "Event Title",
      people: PeopleIcon,
      description:
        "Lorem Ipsum is simply dummy text of the printing and type setting simply dummy text of the printing and type text of the printing and type Lorem torp that can also is simply text of the printing and type setting simply dummy text of the printing and type text of the printing and type text of the printing and type setting text of the printing and type text of the that can on people we will not moving forward going pertetion on that can on itill united to people on demand on your time yet on demand your people ground that can on people we will not moving forward going pertetion on that can on itill united to people on demand on your time yet on demand your people that can on people we will not moving forward going pertetion on that can on itill united to people on demand on your time yet on demand that can on people we will not moving forward going pertetion on that can on itill united to people on demand on your time yet on demand your people pertetion on that can on itill united to people on demand on your time yet on demand your people ground that can on people we will not moving forward going pertetion on that can on itill united to on that gone wrong ",
    },
  ]);


  const [visiblePostId, setVisiblePostId] = useState(null);

  const handleClickOutside = (event) => {

    if (visiblePostId && !event.target.closest(".more-btn")) {
      setVisiblePostId(null);
    }
  };

  const toggleVisibility = (id) => {
    setVisiblePostId(visiblePostId === id ? null : id);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [visiblePostId]);



  const handleBack = () => {
    navigate(-1);
  };
  
  const handleEdit = (item) => {
    console.log("item",item);
    
    setEditingData(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingData(null);
  };


  

  const handleSave = (updatedData) => {
    // Logic to save the updated data
    setOpen(false);
    setEditingData(null);
  };

  return (
    <div style={{ maxWidth: '1420px' }}>
      <Box sx={{ paddingLeft: "10px" }}>
        <Typography
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            gap: "15px",
            width: "20%",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={handleBack}
        >
          <img
            src={backImg}
            alt="backImg"
            style={{ height: "29px", width: "29px" }}
          />
          <Typography
            sx={{ color: "#065F46", fontWeight: "500", fontSize: "20px" }}
          >
            Back
          </Typography>
        </Typography>
      </Box>

      <Box
        sx={{
          marginTop: "20px",
          borderRadius: "36px",
          backgroundColor: "#FFF",
          boxShadow: "3px 2px 20px 0px rgba(0, 0, 0, 0.04)",
          maxWidth: "1460px",
          width: "100%",
          // maxHeight: '528px',
          flexShrink: 0,
          padding: "20px",
        }}
      >
        {/* Image Section */}
        <Box
          component="img"
          src={TestingImg}
          alt="event image"
          sx={{
            width: "100%",

            height: {
              xs: "150px",
              sm: "200px",
              md: "250px",
              lg: "270px",
            },
            // objectFit: 'cover',
            borderRadius: "20px",
          }}
        />

        {/* Forum Title and Actions */}
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginTop: "30px" }}
        >
         
         <>
          <Typography
            sx={{
              color: "#362B24",
              fontFamily: "Heebo",
              fontWeight: "600",
              // fontSize: "20px",
              fontSize: { xs: "4vw", sm: "3vw", md: "1.5vw", lg: "20px" },
            }}
          >
            {event.title}
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography
              onClick={(itm) => handleEdit(itm)}
              sx={{ color: "#EA001B", cursor: "pointer", marginRight: "10px" }}
            >
              <img src={EditIcon} />
            </Typography>
            <Typography sx={{ color: "#EA001B", cursor: "pointer" }}>
              <img src={DeleteIcon} />
            </Typography>
          </Box>
         </>
        
        </Grid>



        <Typography
          sx={{
            fontFamily: "Heebo",
            color: "#065F46",
            fontWeight: "400",
            display: "flex",
            gap: "5px",
            fontSize: "12px",
          }}
        >
          <img
            src={PeopleIcon}
            alt="img"
            style={{ width: "17px", height: "17px" }}
          />
          <span>128 Members</span>
        </Typography>

        {/* Description Section */}
        <Typography
          sx={{
            marginTop: "20px",
            color: "#362B24",
            opacity: "0.8",
            fontFamily: "Heebo",

            fontSize: { xs: "3vw", sm: "2vw", md: "1.2vw", lg: "12px" },
          }}
        >
          {event.description}
        </Typography>
      </Box>

      <Typography
        sx={{
          color: "#362B24",
          fontFamily: "Heebo",
          fontWeight: "600",
          // fontSize: "20px",
          marginTop: "20px",
          fontSize: { xs: "4vw", sm: "3vw", md: "1.5vw", lg: "22px" },
          paddingLeft: "10px",
        }}
      >
        All User Post
      </Typography>

      <Grid container spacing={2}>
        {data.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.id}>
            <Box mb={4}
              sx={{
                width: "100%",
                maxWidth: "449px",
                height: "auto",
                flexShrink: 0,
                borderRadius: "22px",
                backgroundColor: "#FFF",
                boxShadow: "1px 2px 30px 0px rgba(0, 0, 0, 0.06)",
                display: "flex",
                flexDirection: "column",
                padding: "16px",
                marginTop: "25px",
              }}
            >
              {/* First Row: Image and Text */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  marginBottom: "10px",
                }}
              >
                <img
                  src={item.imgSrc}
                  alt="Event"
                  style={{
                    borderRadius: "13px",
                    marginRight: "12px",
                    width: "45px",
                    height: "45px",
                    objectFit: "cover",
                  }}
                />
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontSize: "14px",
                      color: "#362B24",
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "11px",
                      color: "#065F46",
                      fontWeight: "400",
                      marginTop: "5px",
                    }}
                  >
                    <img
                      src={Clock}
                      className="me-2"
                      style={{ fontFamily: "Heebo" }}
                    />
                    {item.date}
                  </Typography>
                </Box>
              </Box>

              {/* Second Row: Description */}
              <Typography
                sx={{
                  fontSize: "13px",
                  color: "#362B24",
                  marginBottom: "10px",
                  opacity: 0.6,
                  fontFamily: "Heebo",
                  lineHeight: "24px",
                }}
              >
                {item.description}
              </Typography>

              {/* Third Row: Additional Content */}
              <Box
                sx={{
                  fontSize: "12px",
                  color: "#362B24",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: 1,
                    fontFamily: "Heebo",
                    fontWeight: 400,
                    fontSize: "14px",
                  }}
                >
                  <FavoriteIcon sx={{ color: "red" }} />
                  <span>{item.likes} Likes</span>
                  <Typography
                    sx={{
                      marginLeft: { xs: "0px", xl: "20px" },
                      fontSize: "14px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <img
                      src={Message}
                      alt="Message"
                      style={{ width: "23px", height: "23px" }}
                    />
                    <span>{item.comments} Comments</span>
                  </Typography>
                </Typography>

                <Box sx={{ position: "relative" }}>
                  {/* More Icon */}
                  <Typography
                    onClick={() => toggleVisibility(item.id)}
                    sx={{ cursor: "pointer" }}
                  >
                    <img
                      src={More}
                      alt="More"
                      style={{ width: "24px", height: "24px" }}
                    />
                  </Typography>

                  {/* Conditional Box */}
                  {visiblePostId === item.id && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "100%",
                        left: "-78px",
                        width: "97px",
                        height: "42px",
                        flexShrink: 0,
                        borderRadius: "12px 0px 12px 12px",
                        backgroundColor: "#FFF",
                        boxShadow: "1px 2px 16px 0px rgba(0, 0, 0, 0.10)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#EA001B",
                          fontSize: "13px",
                          fontWeight: "500",
                          fontFamily: "Heebo",
                          cursor: "pointer",
                        }}
                      >
                        Delete Post
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>

      <ForumModal
        open={open}
        handleClose={handleClose}
        handleSave={handleSave}
        editingData={editingData}
      />
    </div>
  );
};

export default ModrationDetail;
