import React from 'react';
import { Card as MuiCard, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/system';

const CustomCard = styled(MuiCard)(({ theme }) => ({
  borderRadius: '24px',
  backgroundColor: '#FFF',
  boxShadow: '3px 2px 20px 0px rgba(0, 0, 0, 0.04)',
  maxWidth: '255px',
  width: '100%',
  height: '262px',
  flexShrink: 0,
  [theme.breakpoints.down('sm')]: {
    width: '100%', 
    height: 'auto',
  },
}));

const CardContentCentered = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  textAlign: 'center',
}));

const Card = ({ data }) => {

  const value = parseFloat(data.text3);

  const textColor = value < 0 ? 'red' : 'green';

  return (
    <CustomCard>
      <CardContentCentered>
        <img src={data.img} alt="Logo" style={{ marginBottom: '16px', maxWidth: '100px', height: 'auto' }} />
        <Typography variant="body2"  sx={{color:'#000',fontFamily:'Heebo',fontWeight:'700',fontSize:'26px'}}>
          {data.text1}
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{opacity:'0.6',color:'#362B24',fontFamily:'Heebo',fontWeight:'400'}} className='mt-2'>
          {data.text2}
        </Typography>
        <Typography variant="body2" color="textSecondary"  style={{ color: textColor }} className='mt-2'>
          {data.text3}
        </Typography>
      </CardContentCentered>
    </CustomCard>
  );
};

export default Card;
