import React, { useState } from "react";
import { AppBar, Toolbar, Typography, IconButton, Badge, Avatar, Box, InputBase, Popper, Paper, ClickAwayListener, Modal, useMediaQuery, TextField, InputAdornment } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import SearchItm from "../assests/search.svg";
import profileImg from '../assests/profile.png';
import permission from '../assests/permision.png';
import payment from '../assests/payment.png';
import logout from '../assests/logout.png';
import bellIcon from '../assests/bellIcon.png';
import Adminimg from '../assests/adminimg.png';
import { styled, alpha } from "@mui/material/styles";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router-dom";
import UserProfile from "../profile/UserProfile";
import CrossIcon from '../assests/croosicon.png';

// modl
const MenuItemStyled = ({ text, onClick, imgSrc, isRed }) => (
  <Box
    onClick={onClick}
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px 16px',
      backgroundColor: '#FFF',
      color: isRed ? '#EA4335' : '#362B24',
      borderRadius: '8px',
      width: '100%',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#F5F5F5',
      },
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <img src={imgSrc} alt={text} style={{ width: 48, height: 48, marginRight: 20 }} />
      <Typography sx={{ fontSize: '14px' }}>{text}</Typography>
    </Box>
    <ChevronRight sx={{ color: isRed ? '#EA4335' : 'inherit' }} />
  </Box>
);

const routeToDisplayName = {
  'dashboard': 'Dashboard',
  'user-management': 'User Management',
  'vender-management': 'Vender Management',
  'all-order': 'All Orders',
  'content-moderation': 'Content Moderation',
  'report-analysis': 'Report & Analysis',
  'event-management': 'Event Management',
  'push-notification': 'Push Notification',
  'help-support': 'Help & Supports',
  'user-permission': 'User Permission',
  'payment-details': 'Payment Transection',

  
};

const formatLink = (link) => {
  if (routeToDisplayName[link]) {
    return routeToDisplayName[link];
  }
};


const Navbar = ({ OpenSidebar, isMdOrAbove ,activeLink}) => {


  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);

  const isSmallScreen = useMediaQuery('(max-width:480px)');
  const isNotificationOpen = Boolean(notificationAnchorEl);
  const handleCloseNotification = () => {

    setNotificationAnchorEl(null);
  };

  const handleNotificationClick = (event) => {
    setNotificationAnchorEl(notificationAnchorEl ? null : event.currentTarget);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };



  const handleClickAway = () => {
    setOpen(false);
    // setNotificationAnchorEl(null);

  };

  const handleProfileClick = () => {
    setModalOpen(true);
    setOpen(false);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };


  const handlePermissionClick = () => {
    navigate('/user-permission')
    setOpen(false)
  };


  const handlePaymentClick = () => {
    navigate("/payment-details")
    setOpen(false)
  };

  const handleLogoutClick = () => {
    navigate('/')
  };


  return (
    <>
      <AppBar position="static" color="transparent" elevation={0} sx={{ marginTop: "20px" }}>
        <Toolbar>
          {/* Hamburger menu for small screens */}
          {!isMdOrAbove && (
            <IconButton edge="start" color="inherit" aria-label="open drawer" sx={{ display: { xs: "block", transition: 'all 0.3s' } }} onClick={OpenSidebar}>
              <MenuIcon />
            </IconButton>
          )}

          {/* Dashboard text and search bar together */}
          <Typography className="srchNab" sx={{
            display: { xs: "none", sm: 'block' }, marginLeft: { sm: "270px", lg: '350px' }, 
            fontSize: {sm:'20px',md:'28px'}, 
            fontWeight: '700', marginRight: '20px',
            '@media (max-width: 600px)': {
              display: 'none !important',
              marginLeft: '350px !important',
            },

            '@media (max-width: 800px)': {
              marginLeft: '120px !important',
            },

            '@media (max-width: 1024px)': {
              marginLeft: '60px !important',
            },
            '@media (max-width: 1200px)': {
              marginLeft: '320px',
            },

          }}>
             {formatLink(activeLink) || "Dashboard"}
          </Typography>
          {/* <Search sx={{ display: { xs: "none", md: "block" } }}>
            <SearchIconWrapper>
              <SearchIcon sx={{ opacity: '0.35' }} />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search by here"
              inputProps={{ "aria-label": "search" }}
              sx={{
                borderRadius: "33px",
                backgroundColor: "#bbbbbb",
                height: "55px",
                fontSize: "14px",
                opacity: "0.3",
                width: "280px",
              }}
            />
          </Search> */}
          <TextField

            placeholder="Search..."
            sx={{
              width: { xs: '20%', md: 180, lg: 240, xl: 280 },
              // maxWidth:'280px',
              // width:'100%',
              // marginLeft:{xs:'10px',sm:'90px',md:'90px'},

              height: { xs: 52, sm: 58 },
              flexShrink: 0,
              borderRadius: "33px",
              background: "#EBEBEB",
              display: { xs: "none", sm: "block", md: 'block' },
              border: "none",
              "& .MuiOutlinedInput-root": {
                borderRadius: "33px",
                height: "100%",
                paddingRight: "10px",
                outline: "none",
                border: "none",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={SearchItm}
                    sx={{ color: "#000", opacity: "0.35" }}
                  />
                </InputAdornment>
              ),
              style: { height: "100%" },
            }}
          />

          {/* Spacer */}
          <div style={{ flexGrow: 1 }} />

          {/* Notification Icon */}
          <IconButton size="large" aria-label="show 4 new mails" color="inherit" onClick={handleNotificationClick}>
            <Badge>
              <Avatar src={bellIcon} />
            </Badge>
          </IconButton>


          {/* notificarion modal */}

          <ClickAwayListener onClickAway={handleClickAway}>
            <Popper
              open={isNotificationOpen}
              anchorEl={notificationAnchorEl}
              placement="bottom-start"
              onClick={handleNotificationClick}
              sx={{
                zIndex: 10,
                width: '406px',
                maxWidth: '90%',
                borderRadius: '32px',
                boxShadow: 12,
              }}
            >
              <Paper
                sx={{
                  width: '100%',
                  height: '400px',
                  background: '#FFF',
                  borderRadius: '32px',
                  padding: 3,
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography id="modal-title" sx={{ fontSize: '20px', color: '#362B24', fontWeight: '700' }}>
                    Notification
                  </Typography>
                  <IconButton >
                    <img src={CrossIcon} alt="Cross" style={{ height: isSmallScreen ? '20px' : '20px', width: isSmallScreen ? '20px' : '20px' }} />
                  </IconButton>
                </Box>

              </Paper>
            </Popper>
          </ClickAwayListener>



          {/* Profile Section */}

          <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={handleProfileMenuOpen}>
            <IconButton edge="end" aria-label="account of current user" aria-haspopup="true" color="inherit">
              <Avatar alt="Admin name" src={Adminimg} />
            </IconButton>
            <div style={{ marginLeft: "10px", cursor: "pointer" }}>
              <Typography variant="body1" style={{ color: "#333" }}>
                Admin name
              </Typography>
              <Typography variant="body2" style={{ color: "#999" }}>
                Admin@gmail.com
              </Typography>
            </div>
            <IconButton edge="end" aria-label="account of current user" aria-haspopup="true" color="inherit">
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>




      {/* Profile Menu Popper */}
      <Popper open={open} anchorEl={anchorEl} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper sx={{ width: '300px', borderRadius: '24px', padding: '8px', boxShadow: 24 }}>
            <MenuItemStyled text="Manage Profile" onClick={handleProfileClick} imgSrc={profileImg} />
            <MenuItemStyled text="User Permission" onClick={handlePermissionClick} imgSrc={permission} />
            <MenuItemStyled text="Payment Transaction" onClick={handlePaymentClick} imgSrc={payment} />
            <MenuItemStyled text="Logout" onClick={handleLogoutClick} imgSrc={logout} isRed />
          </Paper>
        </ClickAwayListener>
      </Popper>

      <UserProfile
        open={modalOpen}
        onClose={handleCloseModal}
      />
    </>
  );
};

export default Navbar;
