import React, { useState } from 'react'
import EyeIcon from "../../common/assests/eyegreen.png";
import Adminimg from "../../common/assests/adminimg.png";
import CrossIcon from "../../common/assests/croosicon.png";
import DeletIcon from "../../common/assests/deleticon.png";
import SearchItem from "../../common/assests/search.svg";
import SearchIcon from "@mui/icons-material/Search";
import { Box, IconButton, Paper, Pagination, PaginationItem, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, Dialog, DialogContent, DialogTitle, Modal, TextField, InputAdornment, } from '@mui/material';
import Paginations from '../../common/pagination/Paginations';
import StarIcon from '@mui/icons-material/Star';

import TestingImg from "../../common/assests/gridimg3.png";
import Clock from "../../common/assests/clock.svg";
const AllProductList = () => {

    const [selectedRow, setSelectedRow] = useState(null);
    const isSmallScreen = useMediaQuery("(max-width:480px)"); 
    
    const images = [
        TestingImg,
        TestingImg,
        TestingImg,
        TestingImg
      ]
    const [open, setOpen] = useState(false);

    const handleClickOpen = (row) => {
      setSelectedRow(row);
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const [rows, setRows] = useState([
        {
          id: 1,
          userImage: TestingImg,
          businessName: "Business A",
          address: "abc",
          totalPrice: "category",
          quantity: 4.3,
          paymentStatus: "description",
          status: "$20",
          discountPrice: '$12',
          status1:"active"
        },
        {
          id: 2,
          userImage: TestingImg,
          businessName: "Business A",
          address: "abc",
          totalPrice: "category",
          quantity: 3.2,
          paymentStatus: "description",
          status: "$20",
          discountPrice: '$12',
           status1:"active"
        },
      ]);
  return (
    <div>
         <Box sx={{ marginTop: "40px", marginBottom: "30px" }}>
        <TableContainer
          component={Paper}
          sx={{ padding: "26px", borderRadius: "24px" }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <Typography
              sx={{ fontSize:{xs:'16px',sm:'20px'}, fontWeight: "600", color: "#362B24" }}
            >
             Product List
            </Typography>
            <TextField
          
           placeholder="Search..."
           sx={{
             width: { xs: '60%', sm: 280 }, 
             height: {xs:45,sm:62},
             flexShrink: 0,
             borderRadius: "33px",
             background: "#EBEBEB",
             
             border: "none", 
             "& .MuiOutlinedInput-root": {
               borderRadius: "33px",
               height: "100%",
               paddingRight: "10px",
               outline: "none",
               border: "none", 
             },
             "& .MuiOutlinedInput-notchedOutline": {
               border: "none", 
             },
           }}
           InputProps={{
             startAdornment: (
               <InputAdornment position="start">
                 <img src={SearchItem}
                   sx={{ color: "#000", opacity: "0.35" }}
                 />
               </InputAdornment>
             ),
             style: { height: "100%" },
           }}
         />
          </Box>

        
 
        

          <Table sx={{ minWidth: 650 }} aria-label="custom table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: "#362B24", fontWeight: "600", fontFamily: "Heebo", fontSize: "16px" }}>Image</TableCell>
                <TableCell sx={{ color: "#362B24", fontWeight: "600", fontFamily: "Heebo", fontSize: "16px" }}>Name</TableCell>
                
                <TableCell sx={{ color: "#362B24", fontWeight: "600", fontFamily: "Heebo", fontSize: "16px" }}>Category</TableCell>
                <TableCell sx={{ color: "#362B24", fontWeight: "600", fontFamily: "Heebo", fontSize: "16px" }}>Description</TableCell>
                <TableCell sx={{ color: "#362B24", fontWeight: "600", fontFamily: "Heebo", fontSize: "16px" }}>Review & Rating</TableCell>
                <TableCell sx={{ color: "#362B24", fontWeight: "600", fontFamily: "Heebo", fontSize: "16px" }}>Price</TableCell>
                <TableCell sx={{ color: "#362B24", fontWeight: "600", fontFamily: "Heebo", fontSize: "16px" }}>Discounted Price</TableCell>
                <TableCell sx={{ color: "#362B24", fontWeight: "600", fontFamily: "Heebo", fontSize: "16px" }}>Status</TableCell>
                <TableCell sx={{ color: "#362B24", fontWeight: "600", fontFamily: "Heebo", fontSize: "16px" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={row.userImage}
                        alt={row.userName}
                        style={{
                          height: "35px",
                          width: "35px",
                          marginRight: "8px",
                          borderRadius: "50%",
                        }}
                      />
                      <Typography>{row.userName}</Typography>
                    </Box>
                  </TableCell>
                  {/* <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography>{row.businessName}</Typography>
                    </Box> */}
                  {/* </TableCell> */}
                  <TableCell>{row.address}</TableCell>
                  <TableCell>{row.totalPrice}</TableCell>
                  <TableCell>{row.paymentStatus}</TableCell>
                  <TableCell> <StarIcon sx={{ color: "#FFD700",fontSize:'20px' }} />
                                <StarIcon sx={{ color: "#FFD700",fontSize:'20px' }} />
                                <StarIcon sx={{ color: "#FFD700",fontSize:'20px' }} />
                                <StarIcon sx={{ color: "#FFD700",fontSize:'20px' }} />
                                <StarIcon sx={{ color: "#FFD700",fontSize:'20px' }} />{row.quantity}</TableCell>
                  <TableCell>{row.status}</TableCell>
                 
                  <TableCell>{row.discountPrice}</TableCell>
                  <TableCell>{row.status1}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleClickOpen(row)}>
                      <img
                        src={EyeIcon}
                        alt="viewicon"
                        style={{
                          height: isSmallScreen ? "35px" : "45px",
                          width: isSmallScreen ? "35px" : "45px",
                        }}
                      />
                    </IconButton>
                  
                
                    <IconButton >
                      <img
                        src={DeletIcon}
                        alt="DeletIcon"
                        style={{
                          height: isSmallScreen ? "35px" : "45px",
                          width: isSmallScreen ? "35px" : "45px",
                        }}
                      />
                    </IconButton>
                    </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          

          <Paginations/>
       
          

        </TableContainer>
      </Box>



      <Modal
  open={open}
  onClose={handleClose}
>
  <Box
    sx={{
      // maxWidth: '874px',
      maxWidth: '630px',
      width: '90%',
      maxHeight: '80vh',
      bgcolor: '#F5F5F5',
      borderRadius: '24px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      boxShadow: 24,
      p: '20px',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    }}
  >
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography sx={{fontSize:'20px',fontWeight:'700'}}>Product Details</Typography>
      <IconButton onClick={handleClose}>
        <img
          src={CrossIcon}
          alt="img"
          style={{ width: '20px', height: '20px', cursor: 'pointer' }}
        />
      </IconButton>
    </Box>

    {selectedRow && (
      <Box sx={{marginTop:"20px"}}>
        <img
          src={selectedRow.userImage}
          alt={selectedRow.businessName}
          style={{
            width: '100%',
            height: 'auto',
            maxHeight: '231px',
            borderRadius: '24px',
            marginBottom: '20px',
          }}
        />
<Box
  sx={{
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(100px, 0fr))",
    gap:"5px"
  }}
>
  {images.map((image, index) => (
    <Box
      key={index}
      sx={{
        width: "77px",
        height: "79px",
        flexShrink: 0,
        borderRadius: "16px",
        boxShadow: "3px 2px 20px 0px rgba(0, 0, 0, 0.04)",
        overflow: "hidden", 
        "@media (max-width: 767px)": {
          width: "100%",
          height: "auto",
        },
      }}
    >
      <img
        src={image}
        alt="img"
        style={{
          width: "100%",
         
          height: "100%",
          maxHeight:'80px',
          objectFit: "cover",
        }}
      />
    </Box>
  ))}
</Box>


         <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(120px, 0fr))",
                  gap: 3,
                  marginTop:'25px'
                }}

              >
                <Box>
                  <Typography
                    sx={{ fontWeight: "400",fontSize:'13px', opacity: "0.6", color: "#362B24" }}
                  >
                    Product Name
                  </Typography>
                  <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                    <strong>{selectedRow.businessName}</strong>
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{ fontWeight: "400",fontSize:'13px', opacity: "0.6", color: "#362B24" }}
                  >
                    Product Category
                  </Typography>
                  <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                    <strong>{selectedRow.address}</strong>
                  </Typography>
                </Box>
               
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      opacity: "0.6",
                      color: "#362B24",
                      fontSize: "15px",
                    }}
                  >
                    Price
                  </Typography>
                  <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                    <strong>{selectedRow.status}</strong>
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{ fontWeight: "400",fontSize:'13px', opacity: "0.6", color: "#362B24" }}
                  >
                    Discount Price
                  </Typography>
                  <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                    <strong>{selectedRow.discountPrice}</strong>
                  </Typography>
                </Box>
                {/* <Box>
                  <Typography
                    sx={{ fontWeight: "400",fontSize:'13px', opacity: "0.6", color: "#362B24" }}
                  >
                    Total Price
                  </Typography>
                  <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                    <strong>$45.00</strong>
                  </Typography>
                </Box> */}
              </Box>
                  

                 <Box>
                  <Typography
                    sx={{ fontWeight: "600",fontSize:'16px', color: "#362B24" ,marginTop:'20px'}}
                  >
                    Description
                  </Typography>
                  <Typography sx={{  fontSize: "13px", opacity: "0.5", color: "#362B24",fontWeight:'400',lineHeight:'24px' }}>
                  {"Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic type setting, that can remaining on that printer took a galley of type and scrambled it to make a type specimen so push dummy text of the printing and type setting that."}
                  </Typography>
                </Box>


                <Box>
                  <Typography
                    sx={{ fontWeight: "600",fontSize:'16px', color: "#362B24" ,marginTop:'20px',}}
                  >
                    Review & Rating
                  </Typography>
                  <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: '10px',
          gap:'20px',
          flexWrap:'wrap',
          paddingBottom: '10px',
        }}
      >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: '10px',
                              marginTop:'20px'
                            }}
                          >
                            {/* Image */}
                            <img
                              src={TestingImg} 
                              alt="Reviewer"
                              style={{
                                width: '46px',
                                height: '46px',
                                borderRadius: '50%',
                              }}
                            />
                            
                            {/* Reviewer Details */}
                                      <Box >
                                        <Typography
                                          sx={{ fontWeight: "700", fontSize: '14px', color: "#362B24" }}
                                        >
                                          Linda Grodan 
                                        </Typography>
                                        <Typography
                                          sx={{ fontSize: "12px", color: "#065F46",fontWeight:'400',marginTop:"5px" }}
                                          

                                        >
                                          <img src={Clock} className='me-2'/>
                                          15 Dec, 2023
                                        </Typography>
                                      </Box>
                          </Box>
        
        {/* Stars */}
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: '4px',
                                }}
                              >
                                <StarIcon sx={{ color: "#FFD700" }} />
                                <StarIcon sx={{ color: "#FFD700" }} />
                                <StarIcon sx={{ color: "#FFD700" }} />
                                <StarIcon sx={{ color: "#FFD700" }} />
                                <StarIcon sx={{ color: "#FFD700" }} />
                                <Typography sx={{fontWeight:'600',fontSize:'15px'}}>4.3</Typography>

                              
                          </Box>
                          

                        
                </Box>
               <Box sx={{borderBottom: "1px solid #E0E0E0"}}>
               <Typography sx={{  fontSize: "13px", opacity: "0.5", color: "#362B24",fontWeight:'400',lineHeight:'24px',marginBottom:'5px' }}>
                  {"Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic type setting, that can remaining on that printer took a galley of type and scrambled it to make a type specimen so push dummy text of the printing and type setting that."}
                  </Typography>
                </Box>
               

            </Box>    
      </Box>
    )}
  </Box>
</Modal>
    </div>
  )
}

export default AllProductList