import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  useMediaQuery,
  useTheme,
  Box,
  Typography,
  IconButton,
  Button,
  TableHead,
  Modal,
  MenuItem,
  Select,
  TextField,
  InputAdornment,
  Pagination,
  Stack,
  PaginationItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import EyeIcon from "../../common/assests/eyegreen.png";
import Adminimg from "../../common/assests/adminimg.png";
import CrossIcon from "../../common/assests/croosicon.png";
import SearchItem from "../../common/assests/search.svg";
import SearchIcon from "@mui/icons-material/Search";
import Paginations from "../../common/pagination/Paginations";
// import './Orders.css';


const AllOrder = () => {
 
  const theme = useTheme();

  const isSmallScreen = useMediaQuery("(max-width:480px)");
  const isMediumScreen = useMediaQuery("(max-width:768px)");

  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [rows, setRows] = useState([
    {
      id: 1,
      userName: "John",
      userImage: Adminimg,
      businessName: "Business A",
      businessImage: Adminimg,
      Email: "abc@gmail.com",
      mono: "123456789",
      address: "123 Street, City, State",
      totalPrice: "$100",
      quantity: 5,
      paymentStatus: "Paid",
      status: "Active",
      CreatedDate: "2024-08-09",
    },
    {
      id: 2,
      userName: "Abc",
      userImage: Adminimg,
      businessName: "Business A",
      businessImage: Adminimg,
      Email: "abc@gmail.com",
      mono: "123456789",
      address: "123 Street, City, State",
      totalPrice: "$100",
      quantity: 5,
      paymentStatus: "Paid",
      status: "Active",
      CreatedDate: "2024-08-09",
    },
  ]);

  const handleClickOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const [rowss, setRowss] = useState([
    {
      id: 1,
      userImage: Adminimg,
      productName: "XYZ",
      catgeory: "ABC",
      quantity: "2",
      regular: "$100",
      itemtotal: "$20",
      discount: "$20",
    },
  ]);

  return (
    <div>
      <Box sx={{ marginTop: "40px", marginBottom: "30px" }}>
        <TableContainer
          component={Paper}
          sx={{ padding: "26px", borderRadius: "24px" }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap:"wrap",
              gap:'10px',
              marginBottom: "20px",
            }}
          >
            <Typography
              sx={{ fontSize:{xs:'16px',sm:'20px'}, fontWeight: "600", color: "#362B24" }}
            >
              Order List
            </Typography>
            <TextField
          
           placeholder="Search..."
           sx={{
             width: { xs: '60%', sm: 280 }, 
             height: {xs:45,sm:62},
             flexShrink: 0,
             borderRadius: "33px",
             background: "#EBEBEB",
             
             border: "none", 
             "& .MuiOutlinedInput-root": {
               borderRadius: "33px",
               height: "100%",
               paddingRight: "10px",
               outline: "none",
               border: "none", 
             },
             "& .MuiOutlinedInput-notchedOutline": {
               border: "none", 
             },
           }}
           InputProps={{
             startAdornment: (
               <InputAdornment position="start">
                  <img src={SearchItem}
                   sx={{ color: "#000", opacity: "0.35" }}
                 />
               </InputAdornment>
             ),
             style: { height: "100%" },
           }}
         />
          </Box>

          <Table sx={{ minWidth: 650 }} aria-label="custom table">
            <TableHead>
              <TableRow>
                <TableCell
                   className="Orders-tbl-cell "
                >
                  Order By
                </TableCell>
                <TableCell
                 className="Orders-tbl-cell "
                >
                  Business Name
                </TableCell>
                <TableCell
                 className="Orders-tbl-cell "
                >
                  Address
                </TableCell>
                <TableCell
                 className="Orders-tbl-cell "
                >
                  Total Price
                </TableCell>
                <TableCell
                 className="Orders-tbl-cell "
                >
                  Quantity
                </TableCell>
                <TableCell
                 className="Orders-tbl-cell "
                >
                  Payment Status
                </TableCell>
                <TableCell
                 className="Orders-tbl-cell "
                >
                  Order Status
                </TableCell>
                <TableCell
                 className="Orders-tbl-cell "
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={row.userImage}
                        alt={row.userName}
                        style={{
                          height: "35px",
                          width: "35px",
                          marginRight: "8px",
                          borderRadius: "50%",
                        }}
                      />
                      <Typography>{row.userName}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={row.businessImage}
                        alt={row.businessName}
                        style={{
                          height: "35px",
                          width: "35px",
                          marginRight: "8px",
                          borderRadius: "50%",
                        }}
                      />
                      <Typography>{row.businessName}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{row.address}</TableCell>
                  <TableCell>{row.totalPrice}</TableCell>
                  <TableCell>{row.quantity}</TableCell>
                  <TableCell>{row.paymentStatus}</TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleClickOpen(row)}>
                      <img
                        src={EyeIcon}
                        alt="viewicon"
                        style={{
                          height: isSmallScreen ? "35px" : "45px",
                          width: isSmallScreen ? "35px" : "45px",
                        }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          
<Paginations/>
        </TableContainer>
      </Box>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            maxHeight: "80vh",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: '630px',
            // maxWidth: "874px",
            width: "90%",
            bgcolor: "#F5F5F5",
            borderRadius: "32px",
            boxShadow: 24,

            overflowY: "auto",
            p: 4,
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "@media (min-width: 1600px)": {
              width: "90vw",
            },
            "@media (max-width: 1400px)": {
              width: "80vw",
            },
            "@media (max-width: 1200px)": {
              width: "60vw",
            },
            "@media (max-width: 1024px)": {
              width: "60vw",
            },
            "@media (max-width: 767px)": {
              width: "80vw",
            },
            "@media (max-width: 576px)": {
              width: "90vw",
            },
            "@media (max-width: 480px)": {
              width: "95vw",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                marginBottom: "16px",
                color: "#362B24",
                fontSize: "20px",
                fontWeight: "700",
              }}
            >
              Order Details
            </Typography>

            <IconButton>
              <img
                src={CrossIcon}
                alt="img"
                onClick={handleClose}
                style={{ width: "20px", height: "20px", cursor: "pointer" }}
              />
            </IconButton>
          </Box>
          {selectedRow && (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "16px",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "500",
                      color: "#362B24",
                    }}
                  >
                    Order By
                  </Typography>
                  <Typography sx={{ marginTop: "15px" }}>
                    <img
                      src={selectedRow.userImage}
                      alt="User"
                      style={{
                        width: "42px",
                        height: "42px",
                        marginRight: "5px",
                      }}
                    />
                    <strong> {selectedRow.userName}</strong>
                  </Typography>
                </Box>
              </Box>
              <br />
              <Box
                sx={{
                  display: "grid",
                //   gridTemplateColumns: "repeat(auto-fit, minmax(120px, 1fr))",
                gridTemplateColumns: "repeat(auto-fit, minmax(142px, 1fr))",
                  gap: 3,
                }}
              >
                <Box>
                  <Typography
                    sx={{ fontWeight: "400", opacity: "0.6", color: "#362B24" }}
                  >
                    Order Date
                  </Typography>
                  <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                    <strong>19 July 2024</strong>
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{ fontWeight: "400", opacity: "0.6", color: "#362B24" }}
                  >
                    Order ID
                  </Typography>
                  <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                    <strong>#895421UH7D45</strong>
                  </Typography>
                </Box>
                <Box>
               
                  <Typography
                    sx={{ fontWeight: "400", opacity: "0.6", color: "#362B24" }}
                  >
                    Order Status
                  </Typography>
                  <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                    <strong>Pending</strong>
                  </Typography>
                </Box>
                 
                
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      opacity: "0.6",
                      color: "#362B24",
                      fontSize: "15px",
                    }}
                  >
                    Payment Status
                  </Typography>
                  <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                    <strong>Completed</strong>
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{ fontWeight: "400", opacity: "0.6", color: "#362B24" }}
                  >
                    Card
                  </Typography>
                  <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                    <strong>Visa</strong>
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    sx={{ fontWeight: "400", opacity: "0.6", color: "#362B24" }}
                  >
                    Total Price
                  </Typography>
                  <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                    <strong>$45.00</strong>
                  </Typography>
                </Box>
                
                <Box>
                  <Typography
                    sx={{ fontWeight: "400", opacity: "0.6", color: "#362B24" }}
                  >
                    Shipping Address
                  </Typography>
                  <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                    <strong>{"ABcd"}</strong>
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{ fontWeight: "400", opacity: "0.6", color: "#362B24" }}
                  >
                    Seller Name
                  </Typography>
                  <Typography variant="body2" sx={{ marginTop: "8px" }}>
                    <img
                      src={selectedRow.userImage}
                      alt="User"
                      style={{
                        width: "42px",
                        height: "42px",
                        marginRight: "5px",
                      }}
                    />
                    <strong> {selectedRow.userName}</strong>
                  </Typography>
                </Box>
               
              </Box>

              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(120px, 1fr))",

                  marginTop: "20px",
                }}
              >
                 

               
              </Box>

              {/* tble */}
              <Box sx={{ marginTop: "40px", marginBottom: "30px" }}>
                <TableContainer
                  component={Paper}
                  sx={{ padding: "26px", borderRadius: "24px" }}
                >
                  <Typography sx={{ fontWeight: "700", color: "#362B24" }}>
                    <div style={{ fontSize: "16px",fontFamily:'Heebo' }}>Order Products</div>
                  </Typography>

                  <Table
                    sx={{ minWidth: 650, marginTop: 2 }}
                    aria-label="custom table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#362B24",
                            fontWeight: "500",
                            fontFamily: "Heebo",
                            fontSize: "16px",
                          }}
                        >
                          Image
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#362B24",
                            fontWeight: "500",
                            fontFamily: "Heebo",
                            fontSize: "16px",
                          }}
                        >
                          Product Name
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#362B24",
                            fontWeight: "500",
                            fontFamily: "Heebo",
                            fontSize: "16px",
                          }}
                        >
                          Category
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#362B24",
                            fontWeight: "500",
                            fontFamily: "Heebo",
                            fontSize: "16px",
                          }}
                        >
                          Quantity
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#362B24",
                            fontWeight: "500",
                            fontFamily: "Heebo",
                            fontSize: "16px",
                          }}
                        >
                          Regular Price
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#362B24",
                            fontWeight: "500",
                            fontFamily: "Heebo",
                            fontSize: "16px",
                          }}
                        >
                          Item Total
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#362B24",
                            fontWeight: "500",
                            fontFamily: "Heebo",
                            fontSize: "16px",
                          }}
                        >
                          Discounted Price
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowss.map((row) => (
                        <TableRow
                          key={row.userName}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>
                            <img
                              src={row.userImage}
                              alt="img"
                              style={{
                                height: "35px",
                                width: "35px",
                                marginRight: "8px",
                                borderRadius: "10px",
                              }}
                            />
                          </TableCell>
                          <TableCell>{row.productName}</TableCell>
                          <TableCell>{row.catgeory}</TableCell>
                          <TableCell>{row.quantity}</TableCell>
                          <TableCell>{row.regular}</TableCell>
                          <TableCell>{row.itemtotal}</TableCell>
                          <TableCell>{row.discount}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {/* <Paginations/> */}
                </TableContainer>
              
              </Box>
            </Box>
          )}
         
        </Box>
      </Modal>
    </div>
  );
};

export default AllOrder;
