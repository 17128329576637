import React from 'react'
import { Box, Typography, TextField, Button, Modal, FormControlLabel, Checkbox, useMediaQuery, IconButton } from '@mui/material';

const ChangePassword = ({onClose}) => {

    const onSave=()=>{
        onClose()
    }
  return (
    <div>


<Typography sx={{
          marginBottom: "8px",
          textAlign: "left",
          fontSize: { xs: "14px", sm: "17px" },
          fontWeight: "600",
          fontFamily: "Heebo",
          textIndent: "18px",
          marginTop:'15px'
        }}>
          Old Password
        </Typography>
        <TextField
          fullWidth
          name="password"
          type="password"
        //   value={data.password}
        //   onChange={handleInputChange}
          placeholder="Type your Old password"
          sx={{
            borderRadius: "28px",
            opacity:'0.4',
            border: "1px solid #362B24",
            '& .MuiOutlinedInput-root': {
              borderRadius: '25px',
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        />


          <Typography sx={{
          marginBottom: "8px",
          textAlign: "left",
          fontSize: { xs: "14px", sm: "17px" },
          fontWeight: "600",
          fontFamily: "Heebo",
          textIndent: "18px",
          marginTop:'15px'
        }}>
          New Password
        </Typography>
        <TextField
          fullWidth
          name="password"
          type="password"
        //   value={data.password}
        //   onChange={handleInputChange}
          placeholder="Type your New password"
          sx={{
            borderRadius: "28px",
            opacity:'0.4',
            border: "1px solid #362B24",
            '& .MuiOutlinedInput-root': {
              borderRadius: '25px',
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        />

        {/* Retype Password Field */}
        <Typography sx={{
          marginBottom: "8px",
          textAlign: "left",
          fontSize: { xs: "14px", sm: "17px" },
          fontWeight: "600",
          fontFamily: "Heebo",
          textIndent: "18px",
          marginTop:'15px'
        }}>
          Retype Password
        </Typography>
        <TextField
          fullWidth
          name="retypePassword"
          type="password"
        //   value={data.retypePassword}
        //   onChange={handleInputChange}
          placeholder="Retype your password"
          sx={{
            borderRadius: "28px",
            opacity:'0.4',
            border: "1px solid #362B24",
            '& .MuiOutlinedInput-root': {
              borderRadius: '25px',
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        />
        <Box sx={{display:'flex',justifyContent:'center',marginTop:'20px'}}>
          <Button
            onClick={() => onSave()}
            sx={{
              mt: 2,
              width: '337px',
              height: '57px',
              borderRadius: '28.5px',
              background: 'linear-gradient(180deg, #065F46 0%, #0D7B5C 100%)',
              color: 'white',
              textTransform: 'none',
              fontSize: '16px', 
              fontWeight: '600', 
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            Save
          </Button>
        </Box>
        

    </div>
  )
}

export default ChangePassword