import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  useMediaQuery,
  useTheme,
  Box,
  Typography,
  IconButton,
  Button,
  TableHead,
  Modal,
} from "@mui/material";
import EyeIcon from "../../common/assests/eyeicon.png";
import Adminimg from "../../common/assests/adminimg.png";
import CrossIcon from "../../common/assests/croosicon.png";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import CallIcon from '@mui/icons-material/Call';
import Paginations from "../../common/pagination/Paginations";

const PendingApprove = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width:480px)");

  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [rows, setRows] = useState([
    {
      id: 1,
      userName: "Vender Name",
      userImage: Adminimg,
      businessName: "Business A",
      businessImage: Adminimg,
      Email: "abc@gmail.com",
      mono: "(+41) 789 441 6542",
      street: "123 Street",
      address:"US",
      city: "abc",
      state: "xyz",
      zipcode: "12345",
      country: "America",
      totalPrice: "$100",
      quantity: 5,
      paymentStatus: "Paid",
      status: "Active",
      CreatedDate: "2024-08-09",
    },
    {
      id: 2,
      userName: "Vender Namess",
      userImage: Adminimg,
      businessName: "Business A",
      businessImage: Adminimg,
      Email: "abc@gmail.com",
      mono: "(+41) 789 441 6542",
      street: "123 Street",
      address:"London",
      city: "abcd",
      state: "xyzw",
      zipcode: "1266665",
      country: "Canada",
      totalPrice: "$100",
      quantity: 5,
      paymentStatus: "Paid",
      status: "Active",
      CreatedDate: "2024-08-09",
    },
  ]);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);

 
  const handleRejectModalOpen = () => setRejectModalOpen(true);
  const handleRejectModalClose = () => setRejectModalOpen(false);

  const handleClickOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  return (
    <div>
      <Box sx={{ marginTop: "40px", marginBottom: "30px" }}>
        <TableContainer
          component={Paper}
          sx={{ padding: "26px", borderRadius: "24px" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="custom table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    color: "#362B24",
                    fontWeight: "600",
                    fontFamily: "Heebo",
                    fontSize: "16px",
                  }}
                >
                  Business Name
                </TableCell>
                <TableCell
                  sx={{
                    color: "#362B24",
                    fontWeight: "600",
                    fontFamily: "Heebo",
                    fontSize: "16px",
                  }}
                >
                  Email
                </TableCell>
                <TableCell
                  sx={{
                    color: "#362B24",
                    fontWeight: "600",
                    fontFamily: "Heebo",
                    fontSize: "16px",
                  }}
                >
                  Mobile no
                </TableCell>
                <TableCell
                  sx={{
                    color: "#362B24",
                    fontWeight: "600",
                    fontFamily: "Heebo",
                    fontSize: "16px",
                  }}
                >
                  Address
                </TableCell>
                <TableCell
                  sx={{
                    color: "#362B24",
                    fontWeight: "600",
                    fontFamily: "Heebo",
                    fontSize: "16px",
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  sx={{
                    color: "#362B24",
                    fontWeight: "600",
                    fontFamily: "Heebo",
                    fontSize: "16px",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={row.userImage}
                        alt={row.userName}
                        style={{
                          height: "45px",
                          width: "45px",
                          marginRight: "8px",
                          borderRadius: "50%",
                        }}
                      />
                      <Typography>{row.userName}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{row.Email}</TableCell>
                  <TableCell>{row.mono}</TableCell>
                  <TableCell>{row.address}</TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <IconButton onClick={() => handleClickOpen(row)}>
                      <img
                        src={EyeIcon}
                        alt="viewicon"
                        style={{
                          height: isSmallScreen ? "35px" : "42px",
                          width: isSmallScreen ? "35px" : "45px",
                        }}
                      />
                    </IconButton>
                    <Button
                      sx={{
                        width: "94px",
                        height: "44px",
                        borderRadius: "15px",
                        backgroundColor: "#065F46",
                        color: "#FFF",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#065F46",
                        },
                        flexShrink: 0,
                      }}
                    >
                      Accept
                    </Button>
                    <Button
                        onClick={() => {
                            setSelectedRow(row); 
                            handleRejectModalOpen();
                          }}
                      sx={{
                        width: "94px",
                        height: "44px",
                        borderRadius: "15px",
                        backgroundColor: "#EA001B",
                        color: "#FFF",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#EA001B",
                        },
                        flexShrink: 0,
                      }}
                    >
                      Reject
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              
            </TableBody>
           
          </Table>

          <Paginations/>
{/* view modal  */}
          <Modal open={open} onClose={handleClose}>
  <Box
    sx={{
      maxHeight: "75vh",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "681px",
      width: "90%",
      bgcolor: "#FFFFFF",
      borderRadius: "32px",
      boxShadow: 24,
      overflowY: "auto",
      p: 4,
      "&::-webkit-scrollbar": {
        display: "none",
      },
    }}
  >
    {/* Header */}
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
      }}
    >
      <Typography
       
        sx={{
          marginBottom: "16px",
          color: "#362B24",
          fontSize: "20px",
          fontWeight: "700",
        }}
      >
        Vendor Detail
      </Typography>

      <IconButton onClick={handleClose}>
        <img
          src={CrossIcon}
          alt="Close"
          style={{ width: "20px", height: "20px", cursor: "pointer" }}
        />
      </IconButton>
    </Box>

    {/* Modal Content */}
    {selectedRow && (
      <Box sx={{marginTop:1 }}>
        <Box sx={{ textAlign: "center",  }}>
          <img
            src={selectedRow.userImage}
            alt="User"
            style={{
                width: "30%",
                height: "auto",
                maxWidth: "128px",
              marginBottom: "10px",
              borderRadius: "128px",
            }}
          />
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "600",
              color: "#362B24",
              marginBottom: "8px",
              marginTop:'10px'
            }}
          >
            {selectedRow.userName}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "16px",
              color:'#065F46',
              fontSize:'14px'
            }}
          >
            <EmailRoundedIcon sx={{ marginRight: "4px" }} />
            <Typography>{selectedRow.Email}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "8px",
              color:'#065F46',
              fontSize:'14px'
            }}
          >
            <CallIcon sx={{ marginRight: "4px" }} />
            <Typography>{selectedRow.mono}</Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(110px, 1fr))",
            gap: 2,
           
            marginTop:'30px',
          }}
        >
          <Box>
            <Typography
              sx={{
                fontWeight: "400",
                opacity: "0.6",
                color: "#362B24",
              }}
            >
              Address
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
              {selectedRow.address}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: "400",
                opacity: "0.6",
                color: "#362B24",
              }}
            >
              City
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
              {selectedRow.city}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: "400",
                opacity: "0.6",
                color: "#362B24",
              }}
            >
              State
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
              {selectedRow.state}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: "400",
                opacity: "0.6",
                color: "#362B24",
              }}
            >
              Country
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
              {selectedRow.country}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: "400",
                opacity: "0.6",
                color: "#362B24",
              }}
            >
              Zip Code
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
              {selectedRow.zipcode}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Typography
            sx={{
              fontWeight: "600",
              marginTop:'20px',
              color: "#362B24",
             
              fontSize:'15px',

            }}
          >
            Description
          </Typography>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: {xs:'12px',sm:'13px',md:'16px'},
              color: "#362B24",
              opacity:0.6, marginTop:'8px',
              
            }}
          >
           Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic type setting, that can remaining on that printer took a galley of type and scrambled it to make a type specimen so push dummy text of the printing and type setting that.
           Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an Ipsum has been the industry's standard dummy text that can also Ipsum is simply dummy text of the printing and type setting industry told printer
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            flexWrap: "wrap", marginTop:'25px',
          }}
        >
          <Button
           onClick={() => {
           
            handleClose();
          }}
            sx={{
              width: "180px",
              height: "50px",
              borderRadius: "15px",
              backgroundColor: "#065F46",
              color: "#FFF",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#065F46",
              },
              flexShrink: 0,
            }}
          >
            Accept
          </Button>
          <Button
        sx={{
          width: "180px",
          height: "50px",
          borderRadius: "15px",
          backgroundColor: "#EA001B",
          color: "#FFF",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#EA001B",
          },
          flexShrink: 0,
        }}
        onClick={handleRejectModalOpen}
      >
        Reject
      </Button>
        </Box>
      </Box>
    )}
  </Box>
        </Modal>

        </TableContainer>
      </Box>


{/* rejected modal */}


<Modal
        open={rejectModalOpen}
        onClose={handleRejectModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            maxWidth: '538px',
            width:'90%',
            height: '418px',
            borderRadius: '32px',
            backgroundColor: '#FFF',
            display: 'flex',
            flexDirection: 'column', 
            p: 3,
            mx: 'auto',
            my: 3,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography
              sx={{
                color: '#362B24',
                fontSize: '20px',
                fontWeight: '700',
              }}
            >
              Reason for Rejection
            </Typography>

            <IconButton onClick={handleRejectModalClose}>
              <img
                src={CrossIcon}
                alt="Close"
                style={{ width: '20px', height: '20px', cursor: 'pointer' }}
              />
            </IconButton>
          </Box>

          <Typography sx={{  color: '#362B24',
          marginTop:'15px',
                fontSize: '14px',
                fontWeight: '600',}}>
          Type your Reason
          </Typography>

          <textarea
            placeholder="Type here"

            style={{
              maxWidth: '470px',
              width:'100%',
              height: '202px',
              borderRadius: '18px',
              border: '1.4px solid #362B24',
              opacity: 0.2,
              backgroundColor: '#FFF',
              padding: '16px',
              fontSize: '16px',
              resize: 'none', 
              marginTop:'15px'
            }}
          />

        <Box sx={{ display:'flex',
          justifyContent:'center',mt:3}}>
        <Button
         onClick={() => {
            
            handleRejectModalClose(); 
          }}
        sx={{
          width: "196px",
          height: "50px",
          borderRadius: "15px",
          backgroundColor: "#EA001B",
          color: "#FFF",
          textTransform: "none",
         
          "&:hover": {
            backgroundColor: "#EA001B",
          },
          flexShrink: 0,
        }}
       
      >
        Reject
      </Button>
        </Box>
</Box>
</Modal>
    </div>
  );
};

export default PendingApprove;
