import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Sidebarlogo from '../assests/sidebarLogo.png';
import Usr from '../assests/user.png';
import Logo1 from '../assests/logo1.svg';
import Logo2 from '../assests/logo2.svg';
import Logo3 from '../assests/logo3.svg';
import Logo4 from '../assests/logo4.svg';
import Logo5 from '../assests/logo5.svg';
import Logo6 from '../assests/logo6.svg';
import Logo8 from '../assests/logo8.svg';
import Logo9 from '../assests/logo9.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./SideBar.css";

function SideBar({ openSidebarToggle, OpenSidebar, isMdOrAbove,onActiveLinkChange }) {
  const location = useLocation();
  const currentPath = location.pathname;

  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    const path = location.pathname.split("/").filter((lik) => lik)[0];
    setActiveLink(path);
    onActiveLinkChange(path);
  }, [location.pathname,onActiveLinkChange]);


  
  const handleLinkClick = (section) => {
    console.log(`Navigating to ${section}`);

  };

  
  const isActive = (section) => currentPath.startsWith(section);

  return (
    <>
      <aside
        id="sidebar"
        className={`sidebar-responsive ${
          !isMdOrAbove && !openSidebarToggle ? "d-none" : ""
        }`}>
        <div className="sidebar-title">
          <div className="sidebar-brand">
            <div className="text-center Broom-container">
              <div className="reyleaf-img">
                <img src={Sidebarlogo} alt="sidebar-logo" style={{ width: '120px', height: '150px' }} />
              </div>
            </div>
          </div>
        </div>
        <div className="sidebar-list">
        <div className={`sidebar-list-item ${isActive('/dashboard') ? 'active' : ''} sidebar-list-dash`}>
  <a href="/dashboard" className="anch-icon" onClick={() => handleLinkClick("dashboard")}>
    <div className="icon-txt-combine">
      <span className="dash-icon">
        <img
          src={Logo8}
          alt="dashboard-icon"
          className={`icon ${isActive('/dashboard') ? 'active' : ''}`}
        />
      </span>
      &nbsp;
      <span className="dash-text">Dashboard</span>
    </div>
  </a>
</div>

          <div className={`sidebar-list-item ${isActive('/user-management') ? 'active' : ''} sidebar-list-user`}>
            <a href="/user-management" className="anch-icon" onClick={() => handleLinkClick("user-management")}>
              <div className="icon-txt-combine">
                <span className="user-icon"><img src={Logo1} alt="user-management-icon" /></span>
                &nbsp;
                <span className="user-text">User Management</span>
              </div>
            </a>
          </div>
          <div className={`sidebar-list-item ${isActive('/vender-management') ? 'active' : ''} sidebar-list-vendor`}>
            <a href="/vender-management" className="anch-icon" onClick={() => handleLinkClick("vender-management")}>
              <div className="icon-txt-combine">
                <span className="vendor-icon"><img src={Usr} alt="vendor-management-icon" /></span>
                &nbsp;
                <span className="vendor-text">Vender Management</span>
              </div>
            </a>
          </div>
          <div className={`sidebar-list-item ${isActive('/all-order') ? 'active' : ''} sidebar-list-orders`}>
            <a href="/all-order" className="anch-icon" onClick={() => handleLinkClick("all-order")}>
              <div className="icon-txt-combine">
                <span className="orders-icon"><img src={Logo2} alt="orders-icon" /></span>
                &nbsp;
                <span className="orders-text">Orders</span>
              </div>
            </a>
          </div>
          <div className={`sidebar-list-item ${isActive('/content-moderation') ? 'active' : ''} sidebar-list-content-moderation`}>
            <a href="/content-moderation" className="anch-icon" onClick={() => handleLinkClick("content-moderation")}>
              <div className="icon-txt-combine">
                <span className="content-moderation-icon"><img src={Logo3} alt="content-moderation-icon" /></span>
                &nbsp;
                <span className="content-moderation-text">Content Moderation</span>
              </div>
            </a>
          </div>
          <div className={`sidebar-list-item ${isActive('/report-analysis') ? 'active' : ''} sidebar-list-report-analysis`}>
            <a href="/report-analysis" className="anch-icon" onClick={() => handleLinkClick("report-analysis")}>
              <div className="icon-txt-combine">
                <span className="report-analysis-icon"><img src={Logo4} alt="report-analysis-icon" /></span>
                &nbsp;
                <span className="report-analysis-text">Report & Analysis</span>
              </div>
            </a>
          </div>
          <div className={`sidebar-list-item ${isActive('/event-management') ? 'active' : ''} sidebar-list-event-management`}>
            <a href="/event-management" className="anch-icon" onClick={() => handleLinkClick("event-management")}>
              <div className="icon-txt-combine">
                <span className="event-management-icon"><img src={Logo5} alt="event-management-icon" /></span>
                &nbsp;
                <span className="event-management-text">Event Management</span>
              </div>
            </a>
          </div>
          <div className={`sidebar-list-item ${isActive('/push-notification') ? 'active' : ''} sidebar-list-push-notification`}>
            <a href="/push-notification" className="anch-icon" onClick={() => handleLinkClick("push-notification")}>
              <div className="icon-txt-combine">
                <span className="push-notification-icon"><img src={Logo9} alt="push-notification-icon" /></span>
                &nbsp;
                <span className="push-notification-text">Push Notification</span>
              </div>
            </a>
          </div>
          <div className={`sidebar-list-item ${isActive('/help-support') ? 'active' : ''} sidebar-list-help-support`}>
            <a href="/help-support" className="anch-icon" onClick={() => handleLinkClick("help-support")}>
              <div className="icon-txt-combine">
                <span className="help-support-icon"><img src={Logo6} alt="help-support-icon" /></span>
                &nbsp;
                <span className="help-support-text">Help & Support</span>
              </div>
            </a>
          </div>
        </div>
      </aside>
    </>
  );
}

export default SideBar;
