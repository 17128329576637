import logo from "./logo.svg";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignUp from "./pages/signup/SignUp";
import withMainLayout from "./withMainLayout";
import Dashboard from "./pages/dashboard/Dashboard";
import UserManagement from "./pages/usermanagement/UserManagement";
import VendorManagement from "./pages/vendormanagement/VendorManagement";
import Orders from "./pages/orders/Orders";
import ContentModration from "./pages/contentmodration/ContentModration";
import Report from "./pages/report/Report";
import EventManagement from "./pages/eventmanagement/EventManagement";
import PushNotification from "./pages/pushnotification/PushNotification";
import HelpAndSupport from "./pages/helpsupport/HelpAndSupport";
import PaymentInfo from "./common/paymentinfo/PaymentInfo";
import UserPermission from "./common/userpermission/UserPermission";
import ModrationDetail from "./pages/contentmodration/detailview/ModrationDetail";
import VenderDetails from "./pages/vendormanagement/VenderDetails";
import UserInformation from "./pages/usermanagement/UserInformation";

function App() {
  return (
    <div className="App">
 <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<SignUp/>} />
          <Route path="/dashboard" element={withMainLayout(Dashboard)()} />
          <Route path="/user-management" element={withMainLayout(UserManagement)()} />
          <Route path="/user-management/user-information" element={withMainLayout(UserInformation)()} />
          <Route path="/vender-management" element={withMainLayout(VendorManagement)()} />
          <Route path="/vender-management/vender-details" element={withMainLayout(VenderDetails)()} />
          <Route path="/all-order" element={withMainLayout(Orders)()} />
          <Route path="/content-moderation" element={withMainLayout(ContentModration)()} />
          <Route path="/content-moderation/details" element={withMainLayout(ModrationDetail)()} />
          <Route path="/report-analysis" element={withMainLayout(Report)()} />
          <Route path="/event-management" element={withMainLayout(EventManagement)()} />
          <Route path="/push-notification" element={withMainLayout(PushNotification)()} />
          <Route path="/help-support" element={withMainLayout(HelpAndSupport)()} />
          <Route path="/payment-details" element={withMainLayout(PaymentInfo)()} />
          <Route path="/user-permission" element={withMainLayout(UserPermission)()} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
