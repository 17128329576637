import React, { useState } from 'react'
import './HelpAndSupport.css';

import OpenTicket from './OpenTicket';
import ClosedTicket from './ClosedTicket';
import AllSuportChat  from './AllSuportChat';

const HelpAndSupport = () => { 
  const [activeComponent, setActiveComponent] = useState(0);
  const handleToggle = (index) => {
    setActiveComponent(index);
  };



  return (
    <div>
      <div className="help-support-toggle-container d-flex justify-content-between align-items-center flex-wrap mt-4">
  <div className="help-support-button-box">
    <div
      id="help-support-btn"
      style={{ 
        left: `${activeComponent * 32.5}%`, 
        width: `33.33%` 
      }}
    ></div>
    <button
      className={`help-support-toggle-btn ${activeComponent === 0 ? 'active' : ''}`}
      onClick={() => handleToggle(0)}
    >
      All Support Chat
    </button>
    <button
      className={`help-support-toggle-btn ${activeComponent === 1 ? 'active' : ''}`}
      onClick={() => handleToggle(1)}
    >
      Open Ticket
    </button>
    <button
      className={`help-support-toggle-btn ${activeComponent === 2 ? 'active' : ''}`}
      onClick={() => handleToggle(2)}
    >
      Closed Ticket
    </button>
  </div>
</div>


{activeComponent === 0 && <AllSuportChat />}
{activeComponent === 1 && <OpenTicket />}
{activeComponent === 2 && <ClosedTicket />}

    </div>
  )
}

export default HelpAndSupport