import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, useMediaQuery, useTheme, Box, Typography, IconButton, Button, TableHead, TextField, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '../../common/assests/addmsglogo.png';
import EditIcon from '../../common/assests/editicon.png';
import DeleteIcon from '../../common/assests/deleticon.png';
import Adminimg from '../../common/assests/adminimg.png';
import Paginations from '../../common/pagination/Paginations';
import NotificationModal from './NotificationModal';
import './PushNotification.css';

const PushNotification = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery('(max-width:480px)');
  const [open, setOpen] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [rows, setRows] = useState([
    {
      id: 1,
      srNo: '1',
      sendto: 'User A',
      notificationtitle: 'Welcome Notification',
      notificationtype: 'Email',
      image: Adminimg,
      status: 'Completed',
      pushdate: '10/04/2023',
    },
    {
      id: 2,
      srNo: '2',
      sendto: 'User B',
      notificationtitle: 'Reminder Notification',
      notificationtype: 'SMS',
      image: Adminimg,
      status: 'Pending',
      pushdate: '10/04/2023',
    },
  ]);

  const handleOpenModal = (data) => {
    setEditingData(data);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setEditingData(null);
  };

  const handleDelete = (id) => {
   
  };

  return (
    <div>
      <Box sx={{ marginTop: '40px', marginBottom: '30px' }}>
        <TableContainer component={Paper} sx={{ padding: '26px', borderRadius: '24px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap:'20px' ,alignItems: 'center', marginBottom: '20px' }}>
            <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>All Users Permissions</Typography>
            <Button
                onClick={() => handleOpenModal(null)}
                sx={{
                  borderRadius: '29px',
                  background: 'linear-gradient(180deg, #065F46 0%, #0D7B5C 100%)',
                  boxShadow: '1px 2px 30px 0px rgba(0, 0, 0, 0.06)',
                  width: '235px',
                  height: '58px',
                  color: 'white',
                  display: 'flex',
                  gap: '5px',
                  textTransform: 'none',
                  fontSize: isSmallScreen ? '12px' : '16px',
                  alignItems: 'center',
                  '@media (max-width:600px)': {
                    width: '200px',
                    height: '50px',
                    fontSize: '10px',
                    gap: '2px', 
                  },
                }}
              >
                <img
                  src={AddIcon}
                  alt="Add"
                  style={{
                    height: isSmallScreen ? '20px' : '24px',
                    width: isSmallScreen ? '20px' : '24px',
                    marginRight: isSmallScreen ? '1px' : '3px', 
                  }}
                />
                Create New Notification
              </Button>


          </Box>

          <Table sx={{ minWidth: 650 }} aria-label="custom table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: '#362B24', fontWeight: '600', fontFamily: 'Heebo', fontSize: '16px' }}>Sr. No</TableCell>
                <TableCell sx={{ color: '#362B24', fontWeight: '600', fontFamily: 'Heebo', fontSize: '16px' }}>Send to</TableCell>
                <TableCell sx={{ color: '#362B24', fontWeight: '600', fontFamily: 'Heebo', fontSize: '16px' }}>Notification Title</TableCell>
                <TableCell sx={{ color: '#362B24', fontWeight: '600', fontFamily: 'Heebo', fontSize: '16px' }}>Notification Type</TableCell>
                <TableCell sx={{ color: '#362B24', fontWeight: '600', fontFamily: 'Heebo', fontSize: '16px' }}>Image</TableCell>
                <TableCell sx={{ color: '#362B24', fontWeight: '600', fontFamily: 'Heebo', fontSize: '16px' }}>Status</TableCell>
                <TableCell sx={{ color: '#362B24', fontWeight: '600', fontFamily: 'Heebo', fontSize: '16px' }}>Push Date</TableCell>
                <TableCell sx={{ color: '#362B24', fontWeight: '600', fontFamily: 'Heebo', fontSize: '16px' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>{row.srNo}</TableCell>
                  <TableCell>{row.sendto}</TableCell>
                  <TableCell>{row.notificationtitle}</TableCell>
                  <TableCell>{row.notificationtype}</TableCell>
                  <TableCell><img src={row.image} style={{ width: '45px', height: '45px' }} /></TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>{row.pushdate}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpenModal(row)}>
                      <img src={EditIcon} alt="Edit" style={{ height: isSmallScreen ? '35px' : '45px', width: isSmallScreen ? '35px' : '45px' }} />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(row.id)}>
                      <img src={DeleteIcon} alt="Delete" style={{ height: isSmallScreen ? '35px' : '45px', width: isSmallScreen ? '35px' : '45px' }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Paginations />
        </TableContainer>
      </Box>

      <NotificationModal
        open={open}
        onClose={handleCloseModal}
        editingData={editingData}
      />
    </div>
  );
};

export default PushNotification;
