import React, { useState, useEffect } from "react";
import Adminimg from "../../common/assests/adminimg.png";
import Clock from "../../common/assests/clock.svg";
import More from "../../common/assests/more.svg";
import Posts from "../../common/assests/post.png";
import Posts1 from "../../common/assests/post11.png";
import Message from "../../common/assests/message.svg";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Box, Grid, Typography } from "@mui/material";

const AllPost = () => {
  const [visiblePostId, setVisiblePostId] = useState(null);

  const handleClickOutside = (event) => {

    if (visiblePostId && !event.target.closest(".more-btn")) {
      setVisiblePostId(null);
    }
  };

  const toggleVisibility = (id) => {
    setVisiblePostId(visiblePostId === id ? null : id);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [visiblePostId]);

  const data = [
    {
      id: 1,
      imgSrc: Adminimg,
      name: "Linda Grodan",
      date: "15 Dec, 2023",
      post:Posts,
      post1:Posts1,
      description:
        "Lorem Ipsum is simply dummy text of the printing and type setting to industry has been the industry that can standard dummy ",
      likes: 24,
      comments: 24,
    },
    {
      id: 2,
      imgSrc: Adminimg,
      name: "Linda Grodan",
      date: "15 Dec, 2023",
      post:Posts,
      post1:Posts1,
      description:
        "Lorem Ipsum is simply dummy text of the printing and type setting to industry has been the industry that can standard dummy ",
      likes: 24,
      comments: 24,
    },
    {
      id: 3,
      imgSrc: Adminimg,
      name: "Linda Grodan",
      date: "15 Dec, 2023",
      post:Posts,
      post1:Posts1,
      description:
        "Lorem Ipsum is simply dummy text of the printing and type setting to industry has been the industry that can standard dummy ",
      likes: 24,
      comments: 24,
    },
  ];

  return (
    <div>
      <Typography
        sx={{
          color: "#362B24",
          fontFamily: "Heebo",
          fontWeight: "600",
          marginTop: "40px",
          fontSize: { xs: "4vw", sm: "3vw", md: "1.5vw", lg: "22px" },
          paddingLeft: "10px",
        }}
      >
        All User Post
      </Typography>

      <Grid container spacing={2}>
        {data.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.id}>
            <Box
              sx={{
                width: "100%",
                maxWidth: "449px",
                height: "auto",
                flexShrink: 0,
                borderRadius: "22px",
                backgroundColor: "#FFF",
                boxShadow: "1px 2px 30px 0px rgba(0, 0, 0, 0.06)",
                display: "flex",
                flexDirection: "column",
                padding: "16px",
                marginTop: "25px",
                // overflow:'hidden',
                marginBottom:'30px'
              }}
            >
              {/* First Row: Image and Text */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  marginBottom: "10px",
                }}
              >
                <img
                  src={item.imgSrc}
                  alt="Event"
                  style={{
                    borderRadius: "13px",
                    marginRight: "12px",
                    width: "45px",
                    height: "45px",
                    // objectFit: "cover",
                  }}
                />
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontSize: "14px",
                      color: "#362B24",
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "11px",
                      color: "#065F46",
                      fontWeight: "400",
                      marginTop: "5px",
                    }}
                  >
                    <img
                      src={Clock}
                      className="me-2"
                      style={{ fontFamily: "Heebo" }}
                    />
                    {item.date}
                  </Typography>
                </Box>
              </Box>


                    <Typography sx={{display:'flex',gap:"20px",borderRadius:'17px',mt:'8px',overflow: 'hidden', }}>
                      <img src={item.post1} style={{width:'225px',height:'248px'}}/>
                      <img src={item.post} style={{width:'225px',height:'248px',borderRadius:'20px 0px 0px 20px'}}/>
                    </Typography>

              {/* Second Row: Description */}
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#362B24",
                  marginBottom: "10px",
                  opacity: 0.6,
                  fontFamily: "Heebo",
                  mt:'10px',
                  lineHeight: "20px",
                  maxWidth:'290px',
                  width:'100%'
                }}
              >
                {item.description}
              </Typography>

              
              <Box
                sx={{
                  fontSize: "12px",
                  color: "#362B24",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  // marginBottom:'30px'
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: 1,
                    fontFamily: "Heebo",
                    fontWeight: 400,
                    fontSize: "14px",
                  }}
                >
                  <FavoriteIcon sx={{ color: "red" }} />
                  <span>{item.likes} Likes</span>
                  <Typography
                    sx={{
                      marginLeft: { xs: "0px", xl: "20px" },
                      fontSize: "14px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <img
                      src={Message}
                      alt="Message"
                      style={{ width: "23px", height: "23px" }}
                    />
                    <span>{item.comments} Comments</span>
                  </Typography>
                </Typography>

                <Box sx={{ position: "relative" }}>
                  
                  <Typography
                    onClick={() => toggleVisibility(item.id)}
                    sx={{ cursor: "pointer" }}
                    className="more-btn"
                  >
                    <img
                      src={More}
                      alt="More"
                      style={{ width: "24px", height: "24px" }}
                    />
                  </Typography>

                  {/* Conditional Box */}
                  {visiblePostId === item.id && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "100%",
                        left: "-78px",
                        width: "97px",
                        height: "42px",
                        flexShrink: 0,
                        borderRadius: "12px 0px 12px 12px",
                        backgroundColor: "#FFF",
                        boxShadow: "1px 2px 16px 0px rgba(0, 0, 0, 0.10)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#EA001B",
                          fontSize: "13px",
                          fontWeight: "500",
                          fontFamily: "Heebo",
                          cursor: "pointer",
                        }}
                      >
                        Delete Post
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default AllPost;
