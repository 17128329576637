import { Box, Grid } from '@mui/material'
import React from 'react'

const MonthGraph = () => {
  return (

    <Box mb={4} mt={3}>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <Box
          sx={{
            borderRadius: '24px',
            background: '#FFF',
            boxShadow: '3px 2px 20px 0px rgba(0, 0, 0, 0.04)',
            // height: '467px',
            height:"380px",
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxSizing: 'border-box',
          }}
        >
         
          <Box style={{ width: '100%', height: '100%' }}>Month graph</Box>
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <Box
          sx={{
            borderRadius: '24px',
            background: '#FFF',
            boxShadow: '3px 2px 20px 0px rgba(0, 0, 0, 0.04)',
            // height: '467px',
            height:"380px",
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxSizing: 'border-box',
          }}
        >
        
          <Box style={{ width: '100%', height: '100%' }}>Month graph</Box>
        </Box>
      </Grid>
    </Grid>
  </Box>
  )
}

export default MonthGraph