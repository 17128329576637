import React, { useState } from 'react';
import { Box, Button, IconButton, Typography, useMediaQuery } from '@mui/material';
import AddIcon from '../../common/assests/addmsglogo.png';
import EditIcon from '../../common/assests/editicon.png';
import DeleteIconImg from '../../common/assests/deleticon.png';
import TestingImg from '../../common/assests/gridimg3.png';
import PeopleIcon from '../../common/assests/people.svg';
import Paginations from '../../common/pagination/Paginations';
import ForumModal from './ForumModal'; // Import the new modal component
import EyeIcon from '../../common/assests/eyeicon.png';
import { useNavigate } from 'react-router-dom';
import ModrationDetail from './detailview/ModrationDetail';

const ContentModration = () => {
  const navigate=useNavigate()
  const [open, setOpen] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const isSmallScreen = useMediaQuery('(max-width:480px)');
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [events, setEvents] = useState([
    {
      id: 1,
      eventImg: TestingImg,
      title: "Form Title",
      people:PeopleIcon,

      description: "Lorem Ipsum is simply dummy text of the printing and type setting simply dummy text of the printing and type text of the printing and type Lorem torp that can also is simply text of the printing and type setting simply dummy text of the printing and type text of the printing and type text of the printing and type setting text of the printing and type text of the that can on people we will not moving forward going pertetion on that can on itill united to people on demand on your time yet on demand your people ground that can on people we will not moving forward going pertetion on that can on itill united to people on demand on your time yet on demand your people that can on people we will not moving forward going pertetion on that can on itill united to people on demand on your time yet on demand that can on people we will not moving forward going pertetion on that can on itill united to people on demand on your time yet on demand your people pertetion on that can on itill united to people on demand on your time yet on demand your people ground that can on people we will not moving forward going pertetion on that can on itill united to on that gone wrong .",
    },
    {
      id: 2,
      eventImg: TestingImg,
      title: "Event Title",
      people:PeopleIcon,
      description: "Lorem Ipsum is simply dummy text of the printing and type setting simply dummy text of the printing and type text of the printing and type Lorem torp that can also is simply text of the printing and type setting simply dummy text of the printing and type text of the printing and type text of the printing and type setting text of the printing and type text of the that can on people we will not moving forward going pertetion on that can on itill united to people on demand on your time yet on demand your people ground that can on people we will not moving forward going pertetion on that can on itill united to people on demand on your time yet on demand your people that can on people we will not moving forward going pertetion on that can on itill united to people on demand on your time yet on demand that can on people we will not moving forward going pertetion on that can on itill united to people on demand on your time yet on demand your people pertetion on that can on itill united to people on demand on your time yet on demand your people ground that can on people we will not moving forward going pertetion on that can on itill united to on that gone wrong ",
    },
  ]);

  const handleClickOpen = (data) => {
    setEditingData(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingData(null);
  };

  const handleSave = (formData) => {
    if (editingData) {
      // Update existing event
      setEvents((prevEvents) =>
        prevEvents.map((event) =>
          event.id === editingData.id ? { ...event, ...formData } : event
        )
      );
    } else {
      // Create new event
      const newEvent = {
        id: Date.now(), // Unique ID for the new event
        eventImg: TestingImg, // You might want to use the uploaded image here
        ...formData,
      };
      setEvents((prevEvents) => [...prevEvents, newEvent]);
    }
  };

  const handleDelete = (id) => {
    setEvents((prevEvents) => prevEvents.filter((event) => event.id !== id));
  };


  const handleViewDetails = (event) => {
    navigate("/content-moderation/details", { state: { event } });
  };




  return (
    <div>
      <Box
        sx={{
          borderRadius: '24px',
          background: '#FFF',
          boxShadow: '3px 2px 20px 0px rgba(0, 0, 0, 0.04)',
          padding: '16px',
          width: '100%',
          maxWidth: '1460px',
          margin: 'auto',
          boxSizing: 'border-box',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: '500',
              color: '#362B24',
              fontFamily: 'Heebo',
            }}
          >
            All Forums
          </Typography>

          <Button
            onClick={() => handleClickOpen(null)}
            sx={{
              borderRadius: '29px',
              background: 'linear-gradient(180deg, #065F46 0%, #0D7B5C 100%)',
              boxShadow: '1px 2px 30px 0px rgba(0, 0, 0, 0.06)',
              width: '235px',
              height: '58px',
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              textTransform: 'none',
              fontSize: isSmallScreen ? '12px' : '16px',
              '@media (max-width:600px)': {
                width: '200px',
                height: '50px',
              },
            }}
          >
            <img src={AddIcon} alt="Add" style={{ height: '24px', width: '24px', marginRight: '3px' }} />
            Create New Forum
          </Button>
        </Box>

        <Box sx={{ mt: 3 }}>
          {events.map((item) => (
            <Box
              key={item.id}
              sx={{
                borderRadius: '22px',
                background: '#FFF',
                boxShadow: '1px 2px 30px 0px rgba(0, 0, 0, 0.06)',
                maxWidth: '1380px',
                height: 'auto',
                minHeight: '116px',
                flexShrink: 0,
                marginBottom: '16px',
                marginTop: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '16px',
                boxSizing: 'border-box',
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              {/* Left Side: Image and Event Details */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: { xs: 'column', sm: 'row' },
                  gap: { xs: '20px', sm: '0px' },
                  width: '100%',
                }}
              >
                <img
                  src={item.eventImg}
                  alt="Event"
                  style={{
                    borderRadius: '13px',
                    marginRight: '16px',
                    width: '91px',
                    height: '96px',
                    objectFit: 'cover',
                  }}
                />
                <Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <Typography
                      sx={{
                        fontSize: '17px',
                        fontWeight: '600',
                        color: '#362B24',
                        fontFamily: 'Heebo',
                      }}
                    >
                      {item.title}
                    </Typography>
                  </Box>

                  <Typography sx={{ fontFamily: 'Heebo', color: '#065F46', fontWeight: '400', display: 'flex', gap: '5px', fontSize: '12px' }}>
                    <img src={item.people} alt='img' style={{ width: '17px', height: '17px' }} />
                    <span>128 Members</span>
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: '#7D6F6F',
                      fontFamily: 'Heebo',
                      maxWidth: '900px',
                      width: '100%',
                      opacity: '0.6',
                    }}
                  >
                     {item.description.length > 150 
    ? `${item.description.substring(0, 250)}...` 
    : item.description}
                  </Typography>
                </Box>
              </Box>

              {/* Right Side: Action Icons */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <IconButton>
                  <img
                    onClick={()=>handleViewDetails(item)}
                    src={EyeIcon}
                    alt="View"
                    style={{ height: '44px', width: '45px', marginTop: '3px' }}
                  />
                </IconButton>
                <IconButton onClick={() => handleClickOpen(item)}>
                  <img src={EditIcon} alt="Edit" />
                </IconButton>
                
                <IconButton onClick={() => handleDelete(item.id)}>
                  <img src={DeleteIconImg} alt="Delete" />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>

        <Paginations />
      </Box>

      {/* Modal Component */}
      <ForumModal
        open={open}
        handleClose={handleClose}
        handleSave={handleSave}
        editingData={editingData}
      />
      
    </div>
  );
};

export default ContentModration;
