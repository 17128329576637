import { Pagination, PaginationItem, Stack } from '@mui/material'
import React from 'react'

const Paginations = () => {
  return (
    <div>
         <Stack spacing={2} sx={{marginTop:'10px'}}>
  <Pagination
    count={10}
    shape="rounded"
    renderItem={(item) => (
      <PaginationItem
        {...item}
        sx={{
          '&.Mui-selected': {
            backgroundColor: '#065F46', 
            color: '#FFF', 
          },
        }}
        components={{
          previous: () => <span>Previous</span>,
          next: () => <span>Next</span>,
        }}
      />
    )}
  />
</Stack>
    </div>
  )
}

export default Paginations