import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Modal,
  AvatarGroup,
  Avatar,
  Button,
} from "@mui/material";

import LockIconImg from "../../common/assests/lockicons.png";
import DeleteIconImg from "../../common/assests/deleticon.png";
import TestingImg from "../../common/assests/gridimg3.png";
import ClockIcon from "../../common/assests/clock.svg";
import CrossIcon from "../../common/assests/croosicon.png";
import Adminimg from "../../common/assests/adminimg.png";
import location from "../../common/assests/location.svg";
import DelIcon from "../../common/assests/delIcon.svg";
import Locks from "../../common/assests/lockicon.svg";
import Pb from "../../common/assests/pb.svg";
import WatchLaterRoundedIcon from '@mui/icons-material/WatchLaterRounded';

import DeleteIcon from '@mui/icons-material/Delete'; 
import Paginations from "../../common/pagination/Paginations";


const CreatedEvent = () => {
  const [open, setOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [events, setEvents] = useState([
    {
      id: 1,
      eventImg: TestingImg,
      content: "Event Title",
      cTag: "Event Category",
      clockImg: ClockIcon,
      date: "15 Dec, 2023",
      txt: "Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    },
    {
      id: 2,
      eventImg: TestingImg,
      content: "Event Title",
      cTag: "Category 2",
      clockImg: ClockIcon,
      date: "15 Dec, 2023",
      txt: "Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    },
  ]);
  const [isPublished, setIsPublished] = useState(false);

  const handleLockClick = () => {
    setIsPublished(!isPublished); 
  };

  const handleOpen = (event) => {
    setSelectedEvent(event);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleDelete = (e, id) => {
    e.stopPropagation();
    setEvents((prevEvents) => prevEvents.filter((event) => event.id !== id));
  };
  return (
    <div>
      <Box
        sx={{
          borderRadius: "24px",
          background: "#FFF",
          boxShadow: "3px 2px 20px 0px rgba(0, 0, 0, 0.04)",
          padding: "16px",
          width: "100%",
          maxWidth: "1460px",
          margin: "auto",
          boxSizing: "border-box",
          overflow: "hidden",
          marginTop: "40px"

        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "500",
            color: "#362B24",
            fontFamily: "Heebo",
          }}
        >
         All Created Events
        </Typography>

        <Box>
          {events.map((item) => (
            <Box
              key={item.id}
              onClick={handleOpen}

              sx={{
                borderRadius: "22px",
                background: "#FFF",
                boxShadow: "1px 2px 30px 0px rgba(0, 0, 0, 0.06)",
                maxWidth: "1380px",
                height: "auto",
                minHeight: "116px",
                flexShrink: 0,
                marginBottom: "16px",
                marginTop: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "16px",
                boxSizing: "border-box",
                flexDirection: { xs: "column", sm: "row" },
                cursor:'pointer',
                
              }}
            >
              {/* Left Side: Image and Event Details */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: { xs: "20px", sm: "0px" },
                  width: "100%",
                }}
              >
                <img
                  src={item.eventImg}
                  alt="Event"
                  style={{
                    borderRadius: "13px",
                    marginRight: "16px",
                    width: "91px",
                    height: "96px",
                    objectFit: "cover",
                  }}
                />
                <Box>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <Typography
                      sx={{
                        fontSize: "17px",
                        fontWeight: "600",
                        color: "#362B24",
                        fontFamily: "Heebo",
                      }}
                    >
                      {item.content}
                    </Typography>
                    <img
                      src={item.clockImg}
                      alt="Clock"
                      style={{
                        marginLeft: "12px",
                        width: "16px",
                        height: "16px",
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#065F46",
                        fontFamily: "Heebo",
                        marginLeft: "5px",
                      }}
                    >
                      {item.date}
                    </Typography>
                  </Box>

                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#7D6F6F",
                      fontFamily: "Heebo",

                      // textOverflow: 'ellipsis',
                      // whiteSpace: 'nowrap',
                      maxWidth: "300px",
                      fontWeight: "400",
                    }}
                  >
                    {item.cTag}
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "#7D6F6F",
                      fontFamily: "Heebo",
                      // overflow: 'hidden',
                      // textOverflow: 'ellipsis',
                      // whiteSpace: 'nowrap',
                      maxWidth: "900px",
                      width: "100%",
                      opacity: "0.6",
                    }}
                  >
                    {item.txt}
                  </Typography>
                </Box>
              </Box>

              {/* Right Side: Action Icons */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
             
                <IconButton onClick={handleLockClick}>
                  <img src={LockIconImg} alt="Lock" />
                </IconButton>
                <IconButton onClick={(e) => handleDelete(e, item.id)}>
  <img src={DeleteIconImg} alt="Delete" />
</IconButton>
              </Box>
            </Box>
          ))}
        </Box>
        <Paginations/>
      </Box>

      {/* Modal */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "80vw", sm: "80vw" },
            maxWidth: '630px',
            // maxWidth: "874px",
            // maxHeight:',
            bgcolor: "#FFF",
            borderRadius: "32px",
            boxShadow: 24,
            maxHeight: "80vh", 
            p: 4,
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "@media (min-width: 1600px)": {
              width: "90vw",
            },
            "@media (max-width: 1400px)": {
              width: "80vw",
            },
            "@media (max-width: 1200px)": {
              width: "70vw",
            },
            "@media (max-width: 1024px)": {
              width: "60vw",
            },
            "@media (max-width: 767px)": {
              width: "80vw",
            },
            "@media (max-width: 576px)": {
              width: "90vw",
            },
            "@media (max-width: 480px)": {
              width: "95vw",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "20px", fontWeight: "700" }}>
              Event Details
            </Typography>
            <IconButton onClick={handleClose}>
              <img
                src={CrossIcon}
                alt="img"
                style={{ width: "20px", height: "20px", cursor: "pointer" }}
              />
            </IconButton>
          </Box>

          {selectedEvent && (
            <>
              <img
                src={TestingImg}
                alt="Event"
                style={{
                  width: "100%",
                  maxHeight:'263px',
                  borderRadius: "20px",
                  marginTop: "15px",
                }}
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: 2,
                  mt: 3,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "700",
                    color: "#362B24",
                    fontFamily: "Heebo",
                  }}
                >
                  Event Title
                </Typography>
                <Box
              sx={{
                borderRadius: '28px',
                background: '#362B24',
                boxShadow: '1px 2px 30px 0px rgba(0, 0, 0, 0.06)',
                width: '110px',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                padding: '0 8px', 
                flexShrink: 0,
                gap: 1, 
                display:'flex',
                alignItems:'center'
              }}
            >
             <WatchLaterRoundedIcon sx={{color:'#FFF',width:'18px',height:'18px'}}/>
              <Typography sx={{ color: '#FFF', fontSize: '10px' }}>
                15 Dec, 2023
              </Typography>
            </Box>
              </Box>

              <Typography sx={{fontWeight: "400",
                    color: "#362B24",fontSize:'13px',
                    fontFamily: "Heebo",marginTop:'10px'}}>Event Category
            </Typography>
             

         <Box sx={{display:'flex',justifyContent:'start',alignItems:'center',gap:3,marginTop:'15px',flexWrap:'wrap'}}>
                    
            <Typography>
                <div className="d-flex gap-2">
                <img src={location} />
                <div style={{color:'#065F46',fontSize:'13px',fontWeight:'400',fontFamily:'Heebo'}}>Houston, Willson street Texas, USA</div>
                </div>
                </Typography>
            </Box>


              <Typography
                sx={{
                  fontSize: "17px",
                  fontWeight: "600",
                  color: "#362B24",
                  fontFamily: "Heebo",
                  mt:3
                }}
              >
                Description
              </Typography>
              <Typography sx={{ mb: 2, opacity: 0.5,fontFamily:'Heebo' }}>
                {"Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic type setting, that can remaining on that printer took a galley of type and scrambled it to make a type specimen so push dummy text of the printing and type setting that"}
              </Typography>


{/* btns */}
  <div style={{ display: 'flex', gap: '8px' ,justifyContent:'center',flexWrap:'wrap',marginTop:'25px'}}>
      <button
      
        style={{
          borderRadius: '15px',
          backgroundColor: isPublished ? '#362B24' : '#d7d5d3',
          width: '190px',
          height: '50px',
          flexShrink: 0,
          
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color:isPublished ? '#d7d5d3' : '#362B24',
          fontFamily:'Heebo',
          textTransform:'none',
          border:"none",
          '&:hover': {
            backgroundColor: isPublished ? '#362B24' : '#d7d5d3',
           
          }
        
        }}
        // startIcon={<LockIcon />} 
       
      >
        {isPublished ?  <img src={Pb} className="me-2"/> :  <img src={Locks} className="me-2"/>}
          {isPublished ? 'Publish' : 'Unpublish'}
      </button>
      <button
        style={{
          borderRadius: '15px',
          backgroundColor: '#EA001B',
          width: '190px',
          height: '50px',
          flexShrink: 0,
          color: '#FFF', 
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontFamily:'Heebo',
          border:"none",
            textTransform:'none',
            '&:hover': {
              backgroundColor: '#EA001B', 
              color: '#FFF', 
            }
        
        }}
       
      >
        <img src={DelIcon} className="me-2"/>
        Delete
      </button>
    </div>
            
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default CreatedEvent;
