import { Box, Button, Drawer, Grid, IconButton, InputAdornment, Modal, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import SearchItm from '../../common/assests/search.svg'
import MoreIcon from '../../common/assests/more.svg';
// import SendIcon from '../../common/assests/send.svg';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import WatchLaterRoundedIcon from '@mui/icons-material/WatchLaterRounded';
import CrossIcon from "../../common/assests/croosicon.png";
import SendIcon from "../../common/assests/send.png";
import './HelpAndSupport.css'

import { useMediaQuery, useTheme } from '@mui/material';


const tickets = [
  {id:1, title: 'Ticket Title 1', status: 'Open', date: '15 Dec, 2023 10:35 AM' },
  { id:2,title: 'Ticket Title 2', status: 'Closed', date: '14 Dec, 2023 09:20 AM' },
  { id:3,title: 'Ticket Title 3', status: 'Open', date: '16 Dec, 2023 11:00 AM' },
  { id:4,title: 'Ticket Title 4', status: 'Closed', date: '13 Dec, 2023 08:45 AM' },
  {id:5, title: 'Ticket Title 4', status: 'Closed', date: '13 Dec, 2023 08:45 AM' },
  {id:6, title: 'Ticket Title 4', status: 'Closed', date: '13 Dec, 2023 08:45 AM' },
];


const ClosedTicket = () => {

  const [activeTicketId, setActiveTicketId] = useState(null);

  const handleTicketClick = (ticketId) => {
    setActiveTicketId(ticketId);
  };

  const [isVisible, setIsVisible] = useState(false);
 
  const [open, setOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);

  const theme = useTheme();
const isBelowMd = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerOpen = (ticket) => {
    setSelectedTicket(ticket);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => setDrawerOpen(false);


  const handleOpen=()=>{
    setOpen(true)
    setIsVisible(false);
  }
  const handleClose = () => setOpen(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const messages = [

    { id: 2, text: "heloo abcd 362B24.", type: "outgoing" },
    { id: 2, text: "I have an issue with my order.", type: "outgoing" },
    { id: 3, text: "Can you please provide more details?", type: "incoming" },
    { id: 3, text: "Can you please provide more details?", type: "incoming" },
    { id: 3, text: "Can you please provide more details?", type: "incoming" },
    { id: 3, text: "Can you please provide more details?", type: "incoming" },
  ];

  return (

    <>
    <Box
      sx={{
        borderRadius: '24px',
        backgroundColor: '#FFF',
        boxShadow: '3px 2px 20px 0px rgba(0, 0, 0, 0.04)',
        width: '100%',
        maxWidth: '1460px',
        height: '80vh', 
        flexShrink: 0,
        mt:"40px",
        padding: { xs: 1, sm: 2 },
        boxSizing: 'border-box',
        mb:'30px',
        '@media (max-width:600px)': {
          padding: 1,
        },
        '@media (max-width:400px)': {
          padding: 0.5,
        },
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{ height: '100%', alignItems: 'stretch' }}
      >
        {/* Left Column - Containing all item boxes */}
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <Box
            sx={{
              height: '100%',
              overflowY: 'auto',
              marginTop: 2,
            }}
          >
          <Box sx={{display:'flex',justifyContent:'center'}}>
          <TextField
            placeholder="Search..."
            sx={{ 
              // maxWidth: '350px', 
              width: '90%', 
              height: { xs: 52, sm: 58 }, 
              flexShrink: 0, 
              borderRadius: '33px', 
              background: '#EBEBEB', 
              border: 'none',
              mb:'20px', 
              "& .MuiOutlinedInput-root": { 
                borderRadius: '33px', 
                height: '100%', 
                paddingLeft: '25px', 
                paddingRight: '10px', 
                outline: 'none', 
                border: 'none' 
              }, 
              "& .MuiOutlinedInput-notchedOutline": { 
                border: 'none' 
              } 
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={SearchItm} alt="Search" style={{ color: '#000', opacity: '0.35' }} />
                </InputAdornment>
              ),
              style: { height: '100%' },
            }}
          />
          </Box>


          <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
      {tickets.map((item, index) => (
       <Box
       key={item.id}
       onClick={() => {
         handleTicketClick(item.id);
         handleDrawerOpen(item);
       }}
       sx={{
         borderRadius: '18px',
         border: '1px solid rgba(0, 0, 0, 0.10)',
         background: '#FFF',
         width: '80%',
         height: 'auto',
         flexShrink: 0,
         marginBottom: 2,
         padding: 2, 
         boxSizing: 'border-box',
         display: 'flex',
         flexDirection: 'column',
         justifyContent: 'space-between',
         cursor: 'pointer',
         opacity: activeTicketId === item.id ? 1 : 0.55,
         boxShadow: activeTicketId === item.id
           ? '1px 2px 30px 0px rgba(0, 0, 0, 0.06)'
           : 'none',
         transition: 'all 0.3s ease',
       }}
     >
          {/* Title and Status Row */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',flexWrap:'wrap' }}>
            <Typography sx={{ color: '#362B24', fontSize: '17px', fontWeight: '700', flexShrink: 0, overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {item.title}
            </Typography>
            <Box
              sx={{
                borderRadius: '28px',
                backgroundColor: item.status === 'Open' ? '#EA4335' : '#43A047',
                boxShadow: '1px 2px 30px 0px rgba(0, 0, 0, 0.06)',
                width: '44px',
                height: '22px',
                flexShrink: 0,
                color: '#FFF',
                fontSize: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap:'wrap'
              }}
            >
              {item.status}
            </Box>
          </Box>

          {/* Date/Time Row */}
          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '5px', flexShrink: 0 ,flexWrap:'wrap'}}>
            <WatchLaterRoundedIcon sx={{ width: '20.3px', opacity: '0.55', flexShrink: 0 }} />
            <Typography sx={{ opacity: '0.55', fontSize: '11px', marginLeft: '5px', flexShrink: 0,flexWrap:'wrap' }}>
              {item.date}
            </Typography>
          </Box>

          {/* Description Row */}
          <Typography
            sx={{
              opacity: '0.55',
              fontSize: '11px',
              marginTop: '10px',
              flexShrink: 0,
              // overflow: 'hidden',
              // textOverflow: 'ellipsis',
              // whiteSpace: 'nowrap',
            }}
          >
            {"Lorem Ipsum is simply dummy text of the printing that type setting can people on your all kind industry."}
          </Typography>
        </Box>
      ))}
    </Box>




   </Box>
        </Grid>

        {/* Right Column - Containing the text */}
        <Grid
          item
          xs={12}
          sm={6}
          md={8}
          sx={{
            display: { xs: 'none', sm: 'flex' }, 
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            paddingLeft: { xs: 1, sm: 2 },
            paddingRight: { xs: 1, sm: 2 },
            paddingTop: 2,
            // height: '105%', 
            height: '80vh', 
          }}
        >
          {/* Details at the top */}
   <Box sx={{ marginBottom: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'stretch', mb: 2,padding:'15px' }}>
              <Typography sx={{ display: 'flex', gap: '10px' }}>
                <div style={{ color: '#362B24', fontSize: '17px', fontWeight: '700' }}>
                  {"TIcket title"}
                  <p style={{ opacity: '0.6', fontSize: '12px', fontWeight: '400' }}>Ticket no: #45953</p>
                </div>
                <div
                  style={{
                    borderRadius: '28px',
                    background:  '#EA4335' , 
                    boxShadow: '1px 2px 30px 0px rgba(0, 0, 0, 0.06)',
                    width: '44px',
                    height: '22px',
                    flexShrink: 0,
                    color: '#FFF',
                    fontSize: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {"open"}
                </div>
              </Typography>
              <Typography onClick={() => toggleVisibility()} sx={{ position: 'relative', cursor: 'pointer' }}>
                <img src={MoreIcon} alt="more-icon-logo"  />
               
                  {isVisible && (
                    <Box
                    onClick={handleOpen}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      position: 'absolute',
                      left: '-116px',
                      borderRadius: '12px 0px 12px 12px',
                      background: '#FFF',
                      boxShadow: '1px 2px 16px 0px rgba(0, 0, 0, 0.10)',
                      width: '126px',
                      height: '48px',
                      zIndex: 1000,
                    }}
                  >
                    <Typography fontSize={13} fontFamily="Heebo" color="#362B24" >
                      View Details
                    </Typography>
                  </Box>
                  )}
           
              </Typography>
            </Box>
          </Box>

          {/* Chat space in the middle */}
          <Box
            sx={{
              flexGrow: 1,
              overflowY: 'auto',
              padding: "21px",
             
              borderRadius: '8px',
              marginBottom: 2,

            }}
          >
         <Box
            sx={{
              flexGrow: 1, 
              overflowY: 'auto',
              padding: 1,
              // border: '1px solid rgba(0, 0, 0, 0.1)',
              borderRadius: '8px',
              marginBottom: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {messages.map((message) => (
              <Box
                key={message.id}
                sx={{
                  borderRadius: message.type==='outgoing' ? "18px 18px 0px 18px":'25px 25px 25px 0px',
                  opacity:  message.type==='outgoing' ? "":'0.7',
                    background: message.type === 'outgoing' ? " linear-gradient(112deg, #07523D 7.8%, #168F6D 78.62%)":"#EBEBEB",
                  alignSelf: message.type === 'outgoing' ? 'flex-end' : 'flex-start',
                  color:message.type === 'outgoing'?'white':'black' ,
                  fontSize:'12px',
                  // opacity:"0.8",
                  // backgroundColor: message.type === 'outgoing' ? '#e0f7fa' : '#f1f1f1',
                  padding: '8px 16px',
                  // borderRadius: '18px',
                  marginBottom: 1,
                  maxWidth: '80%',
                 
                }}
              >
                <Typography sx={{textWrap:'wrap'}}>{message.text}</Typography>
              </Box>
            ))}
          </Box>

          </Box>

          {/* Send message button at the bottom */}
   <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',position:'relative',padding:'10px',
     mb:{sm:'20px',xl:'14px'}
   }}>
            <EmojiEmotionsIcon
                sx={{
                  position: 'absolute',
                  left: '20px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  color: '#BDBDBD',
                  zIndex:444
                }}
              />
            <input
              type="text"
              
              style={{
                borderRadius: '28px',
                background: '#EBEBEB',
                width: '87%',
                height: '56px',
                flexShrink: 0,
                border: 'none',
               padding: '0 16px 0 55px',
                outline: 'none',
                flexShrink: 1,

              }}
              />
          <div
              style={{
                cursor: 'pointer',
                marginLeft: '10px',
                height: '56px',
                width: '56px',
                borderRadius: '28px',
                background: 'linear-gradient(112deg, #07523D 7.8%, #168F6D 78.62%)', 
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={SendIcon} alt="send-icon-logo" className='sendIcon-design'/>
            </div>
            </Box>
        </Grid>
      </Grid>

    </Box>
<>

<Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-title"
  aria-describedby="modal-description"
>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '32px',
      backgroundColor: '#FFF',
      width: { xs: '90%', sm: '80%', md: '450px' }, 
      height: { xs: 'auto', sm: 'auto', md: '490px' }, 
      flexShrink: 0,
      margin: 'auto',
      padding: 4, 
      boxShadow: 24,
      outline: 'none',
      display: 'flex',
      flexDirection: 'column', 
    }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 2,
      }}
    >
      <Typography sx={{ fontSize: '20px', color: '#362B24', fontWeight: '700' }}>
        Ticket Details
      </Typography>
      <IconButton onClick={handleClose}>
        <img src={CrossIcon} alt="Cross" style={{ height: '24px', width: '24px' }} />
      </IconButton>
    </Box>

    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography className='modal-texts'>Ticket title</Typography>
      <Typography className='modal-texts-1'>Lorem</Typography>

      <Typography className='modal-texts'>Topic</Typography>
      <Typography className='modal-texts-1'>Topic Name</Typography>

      <Typography className='modal-texts'>Description</Typography>
      <Typography className='modal-texts-1'>Lorem ispusmmmmmmmmmmm</Typography>

      <Typography className='modal-texts'>Status</Typography>
      <Typography className='modal-texts-1'>Open</Typography>

      <Typography className='modal-texts'>Created On</Typography>
      <Typography className='modal-texts-1'>19/20/2000</Typography>
    </Box>
  </Box>
</Modal>







<Drawer
  anchor="right"
  open={drawerOpen && isBelowMd}
  onClose={handleDrawerClose}
  sx={{ width: '80%', flexShrink: 1,}}
>
  {/* <Box
    sx={{
      width: '100%',
      height: '100%',
      padding: 2,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
    }}
  > */}
    {selectedTicket && (
      <Box
        sx={{
          borderRadius: '24px',
          backgroundColor: '#FFF',
          boxShadow: '3px 2px 20px 0px rgba(0, 0, 0, 0.04)',
          // padding: {xs:2,sm:2},
          padding:"10px",
          // paddingLeft:'20px',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography sx={{ fontSize: '17px', fontWeight: '700' }}>
            {selectedTicket.title}
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            <img src={CrossIcon} alt="close-icon" style={{ height: '28px', width: '28px' }} />
          </IconButton>
        </Box>
        <Box sx={{ marginTop: 1 }}>
          <IconButton onClick={handleOpen} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <img src={MoreIcon} alt="view-details-icon" />
          </IconButton>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
            padding: 1,
            borderRadius: '8px',
            marginBottom: 2,
            border: "1px solid",
            mt:'20px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            {messages.map((message) => (
              <Box
                key={message.id}
                sx={{
                  borderRadius: "18px",
                  background: message.type === 'outgoing' ? "linear-gradient(112deg, #07523D 7.8%, #168F6D 78.62%)" : "#EBEBEB",
                  color: message.type === 'outgoing' ? 'white' : 'black',
                  padding: '8px 16px',
                  maxWidth: '80%',
                  alignSelf: message.type === 'outgoing' ? 'flex-end' : 'flex-start',
                }}
              >
                <Typography>{message.text}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
     
     
        <Box 
  sx={{ 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-between', 
    width: '100%' 
  }}
>
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      background: '#EBEBEB',
      borderRadius: '28px',
      width: '100%',
      height: '56px',
      paddingLeft: '16px',
      // width: { xs: '70%', sm: '80%', md: '85%' },
      "@media (max-width: 600px)": {
        height: '48px',
        paddingLeft: '12px',
      },
    }}
  >
    <EmojiEmotionsIcon sx={{ color: '#BDBDBD', marginRight: '8px' }} />
    
    <input
      type="text"
      placeholder="Type a message"
      style={{
        flex: 1,
        border: 'none',
        outline: 'none',
        background: 'transparent',
        padding: '10px 12px',
        fontSize: '16px',
        
      }}
    />
    
    <div style={{ cursor: 'pointer', marginLeft: '10px', height:"45px",width:"45px",borderRadius:'28px',backgroundColor:'#065F46', 
      display:'flex',justifyContent:'center',alignItems:'center',marginRight:'2px'
    }}>
      <img src={SendIcon} alt="send-icon" style={{ width: '15px', height: '15px' ,}} />
    </div>
  </Box>
</Box>

      </Box>
    )}
  {/* </Box> */}
</Drawer>








</>


</>


  );
};

export default ClosedTicket;
