import React from "react";
import Card from "../../common/card/Card";
import dash1 from '../../common/assests/dasimg1.png';
import dash2 from '../../common/assests/dasimg2.png';
import dash3 from '../../common/assests/dasimg3.png';
import dash4 from '../../common/assests/dasimg4.png';
import dash5 from '../../common/assests/dasimg5.png';
import { Card as MuiCard, CardContent, Typography, Box, styled, Grid } from '@mui/material';
import './Dashboard.css'


const CustomCard = styled(MuiCard)(({ theme }) => ({
  borderRadius: '24px',
  backgroundColor: '#FFF',
  boxShadow: '3px 2px 20px 0px rgba(0, 0, 0, 0.04)',
  height: '250px',
  flexShrink: 0,
  marginBottom:"20px",
  
}));

// const CardContentCentered = styled(CardContent)(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   justifyContent: 'center',
//   height: '100%',
//   textAlign: 'center',
// }));

// const GraphPlaceholder = styled(Box)(({ theme }) => ({
//   width: '100%',
//   height: '100px',
//   background: '#f0f0f0',
//   borderRadius: '12px',
//   margin: '16px 0',
// }));


const Dashboard = () => {


  
  const data = [
    { img: dash1, text1: "20k", text2: 'Total Users', text3: '+2.3' },
    { img: dash2, text1: "20k", text2: 'Total Venders', text3: '+2.3' },
    { img: dash3, text1: "20k", text2: 'Total Orders', text3: '+2.3' },
    { img: dash4, text1: "20k", text2: 'Total Product Listed', text3: '+2.3' },
    { img: dash5, text1: "20k", text2: 'Total Earnings', text3: '-2.4' },
  ];

  const newCardData = [
    { img: dash1,},
    { img: dash2,},
  
  ];
  return (
    <div style={{maxWidth:'1520px'}}>
    
    <div className="row g-3 custom-row">
  {data.map((item, index) => (
    <div key={index} className="col-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
      <Card data={item} />
    </div>
  ))}
</div>


      
      <div className="mt-5" style={{fontSize:'22px',fontWeight:'500',color:'#362B24'}}>
        Total Sales
        <p style={{fontSize:'13px',fontWeight:'400',color:'#362B24',opacity:'0.6'}}>This Month</p>
      </div>


      {/* <div className="d-flex justify-content-between flex-wrap gap-1 mt-4">
        {newCardData.map((item, index) => (
          <CustomCard key={index} className="graph-container-width">
          
          </CustomCard>
        ))}
      </div> */}
      
    <Box mb={4} mt={3}>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <Box
          sx={{
            borderRadius: '24px',
            background: '#FFF',
            boxShadow: '3px 2px 20px 0px rgba(0, 0, 0, 0.04)',
            height: '380px',
             // height: '476px',
            maxWidth:'717px'
            ,width:'100%',
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxSizing: 'border-box',
          }}
        >
         
          <Box style={{ width: '100%', height: '100%' }}>graph</Box>
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <Box
           sx={{
            borderRadius: '24px',
            background: '#FFF',
            boxShadow: '3px 2px 20px 0px rgba(0, 0, 0, 0.04)',
            height: '380px',
            // height: '476px',
            maxWidth:'717px'
            ,width:'100%',
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxSizing: 'border-box',
          }}
        >
        
          <Box style={{ width: '100%', height: '100%' }}>graph</Box>
        </Box>
      </Grid>
    </Grid>
  </Box>


</div>
  );
};

export default Dashboard;
