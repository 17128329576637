import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Modal,
  AvatarGroup,
  Avatar,
  Button,
} from "@mui/material";
import Eyegreen from "../../common/assests/eyegreen.png";
import LockIconImg from "../../common/assests/lockicons.png";
import DeleteIconImg from "../../common/assests/deleticon.png";
import TestingImg from "../../common/assests/gridimg3.png";
import ClockIcon from "../../common/assests/clock.svg";
import CrossIcon from "../../common/assests/croosicon.png";
import Adminimg from "../../common/assests/adminimg.png";
import location from "../../common/assests/location.svg";
import DelIcon from "../../common/assests/delIcon.svg";
import Locks from "../../common/assests/lockicon.svg";
import FileIcon from "../../common/assests/file.png";
import WatchLaterRoundedIcon from '@mui/icons-material/WatchLaterRounded';

import DeleteIcon from '@mui/icons-material/Delete'; 
import Paginations from "../../common/pagination/Paginations";


const ResourceAndArticle = () => {
  const [open, setOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [events, setEvents] = useState([
    {
      id: 1,
      eventImg: FileIcon,
      content: "Resource title",
      date: "15 Dec, 2023",
      txt: "Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy,Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    },
    {
      id: 2,
      eventImg: FileIcon,
      content: "Resource title",
      
      txt: "Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    },
  ]);

  const handleOpen = (event) => {
    setSelectedEvent(event);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleDelete = (id) => {
    setEvents((prevEvents) => prevEvents.filter((event) => event.id !== id));
  };

  return (
    <div>
      <Box
        sx={{
          borderRadius: "24px",
          background: "#FFF",
          boxShadow: "3px 2px 20px 0px rgba(0, 0, 0, 0.04)",
          padding: "16px",
          width: "100%",
          maxWidth: "1460px",
          margin: "auto",
          boxSizing: "border-box",
          overflow: "hidden",
          mt:'40px',
          mb:'30px'
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "500",
            color: "#362B24",
            fontFamily: "Heebo",
          }}
        >
          Resource & Articles
        </Typography>

        <Box>
          {events.map((item) => (
            <Box
              key={item.id}
              sx={{
                borderRadius: "22px",
                background: "#FFF",
                boxShadow: "1px 2px 30px 0px rgba(0, 0, 0, 0.06)",
                maxWidth: "1380px",
                height: "auto",
                minHeight: "116px",
                flexShrink: 0,
                marginBottom: "16px",
                marginTop: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "16px",
                boxSizing: "border-box",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              {/* Left Side: Image and Event Details */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: { xs: "20px", sm: "0px" },
                  width: "100%",
                }}
              >
                <img
                  src={item.eventImg}
                  alt="Event"
                  style={{
                    borderRadius: "13px",
                    marginRight: "16px",
                    width: "73px",
                    height: "74px",
                    objectFit: "cover",
                  }}
                />
                <Box>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <Typography
                      sx={{
                        fontSize: "17px",
                        fontWeight: "600",
                        color: "#362B24",
                        fontFamily: "Heebo",
                      }}
                    >
                      {item.content}
                    </Typography>
                   
                   
                  </Box>

                 
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "#7D6F6F",
                      fontFamily: "Heebo",
                      // overflow: 'hidden',
                      // textOverflow: 'ellipsis',
                      // whiteSpace: 'nowrap',
                      maxWidth: "900px",
                      width: "100%",
                      opacity: "0.6",
                    }}
                  >
                    {item.txt}
                  </Typography>
                </Box>
              </Box>

              {/* Right Side: Action Icons */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
               
                <IconButton onClick={() => handleOpen(item)}>
                  <img src={LockIconImg} alt="Lock" />
                </IconButton>
                <IconButton onClick={() => handleDelete(item.id)}>
                  <img src={DeleteIconImg} alt="Delete" />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
        <Paginations/>
      </Box>

      {/* Modal */}
   
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "80vw", sm: "80vw" },
            maxWidth: '630px',
            // maxWidth: "874px",
            // maxHeight:',
            bgcolor: "#F5F5F5",
            borderRadius: "32px",
            boxShadow: 24,
            maxHeight: "80vh", 
            p: 4,
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "@media (min-width: 1600px)": {
              width: "90vw",
            },
            "@media (max-width: 1400px)": {
              width: "80vw",
            },
            "@media (max-width: 1200px)": {
              width: "80vw",
            },
            "@media (max-width: 1024px)": {
              width: "85vw",
            },
            "@media (max-width: 767px)": {
              width: "88vw",
            },
            "@media (max-width: 576px)": {
              width: "90vw",
            },
            "@media (max-width: 480px)": {
              width: "95vw",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "20px", fontWeight: "700" }}>
            Resource & Article Details
            </Typography>
            <IconButton onClick={handleClose}>
              <img
                src={CrossIcon}
                alt="img"
                style={{ width: "20px", height: "20px", cursor: "pointer" }}
              />
            </IconButton>
          </Box>



          {selectedEvent && (
             <Box  sx={{
             mt:'25px',
              width: { xs: "75vw", sm: "78vw", },
              maxWidth: '570px',
              // maxWidth: "804px",
              // maxHeight:',
              bgcolor: "#FFF",
              borderRadius: "20px",
              boxShadow: "3px 2px 20px 0px rgba(0, 0, 0, 0.04)",
              maxHeight: "auto", 
              p: 2,}}
              > 
            <Box>
              <img
                src={TestingImg}
                alt="Event"
                style={{
                  width: "100%",
                  maxHeight:'263px',
                  borderRadius: "20px",
                  marginTop: "15px",
                }}
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: 2,
                  mt: 3,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "700",
                    color: "#362B24",
                    fontFamily: "Heebo",
                  }}
                >
                  Resource Title
                </Typography>
                <Box
              sx={{
                borderRadius: '28px',
                background: '#362B24',
                boxShadow: '1px 2px 30px 0px rgba(0, 0, 0, 0.06)',
                width: '110px',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                padding: '0 8px', 
                flexShrink: 0,
                gap: 1, 
                display:'flex',
                alignItems:'center'
              }}
            >
             <WatchLaterRoundedIcon sx={{color:'#FFF',width:'18px',height:'18px'}}/>
              <Typography sx={{ color: '#FFF', fontSize: '10px' }}>
                15 Dec, 2023
              </Typography>
            </Box>
              </Box>


              <Typography
                sx={{
                  fontSize: "17px",
                  fontWeight: "600",
                  color: "#362B24",
                  fontFamily: "Heebo",
                  mt:3
                }}
              >
                Description
              </Typography>
              <Typography sx={{ mt: 2, opacity: 0.5,fontFamily:'Heebo',fontSize:{xs:'11px',sm:'13px'}}}>
                {selectedEvent.txt}
              </Typography>


{/* btns */}
  
            </Box>
            </Box>
          )}
       
       <div style={{ display: 'flex', gap: '8px' ,justifyContent:'center',flexWrap:'wrap',marginTop:'25px'}}>
      <button
        style={{
          borderRadius: '15px',
          backgroundColor: '#d7d5d3',
          maxWidth: '190px',
          width:'100%',
          height: '50px',
          flexShrink: 0,
          
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color:'#362B24',
          fontFamily:'Heebo',
          textTransform:'none',
          border:"none",
          '&:hover': {
            backgroundColor: '#d7d5d3', 
           
          }
        
        }}
        // startIcon={<LockIcon />} 
      >
         <img src={Locks} className="me-2"/>
        Unpublish
      </button>
      <button
        style={{
          borderRadius: '15px',
          backgroundColor: '#EA001B',
          maxWidth: '190px',
          width:'100%',
          height: '50px',
          flexShrink: 0,
          color: '#FFF', 
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontFamily:'Heebo',
          border:"none",
            textTransform:'none',
            '&:hover': {
              backgroundColor: '#EA001B', 
              color: '#FFF', 
            }
        
        }}
        startIcon={<DeleteIcon />} 
      >
         <img src={DelIcon} className="me-2"/>
        Delete
      </button>
    </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ResourceAndArticle;
