import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import CrossIcon from "../../common/assests/croosicon.png";
import grd from "../../common/assests/gridimg3.png";
import Adminimg from "../../common/assests/adminimg.png";
import ImageUploader from "../../common/imguploader/ImageUploader";
import { Avatar, AvatarGroup, InputAdornment, TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './PushNotification.css'

const NotificationModal = ({ open, onClose, editingData, notificationData, onSave }) => {

    const [value, setValue] = useState('');
    const [selectedValue, setSelectedValue] = useState("notification");
    const [sendTo, setSendTo] = useState("Vender");
    const [notificationDate, setNotificationDate] = useState(null);
    const [time, setTime] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [isImageVisible, setIsImageVisible] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selctUserModal, setSelctUserModal] = useState(false);
    const [closeSelectedUserModal, setCloseSelectedUserModal] = useState(false);
    const [selectedType1, setSelectedType1] = useState('notification');


    const handleCloseSelectedUserModal=()=>{
        // setCloseSelectedUserModal()
        setSelctUserModal(false)
    }
    const handleChangesUser1= (event) => {
        setSelectedType1(event.target.value);
    };

    const handleSelectUserModal = () => {
        setSelctUserModal(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };


    useEffect(() => {
        if (editingData && notificationData) {
            setSelectedValue(notificationData.type || "notification");
            setSendTo(notificationData.sendTo || "Vender");
            setNotificationDate(notificationData.date || null);
            setTime(notificationData.time || "");
            setTitle(notificationData.title || "");
            setDescription(notificationData.description || "");
            setImage(notificationData.image || null);
            setIsImageVisible(!!notificationData.image);
        }
    }, [editingData, notificationData]);

    const handleImageUpload = (uploadedImage) => {
        setImage(uploadedImage);
        setIsImageVisible(true);
    };

    const handleRemoveClick = () => {
        setImage(null);
        setIsImageVisible(false);
    };

    const handleChanges = (event) => {
        setSelectedValue(event.target.value);
    };

    const handleChangesUser = (event) => {
        setSelectedType(event.target.value)
    }

    const handleSave = () => {
        // onSave({
        //     type: selectedValue,
        //     sendTo,
        //     date: notificationDate,
        //     time,
        //     title,
        //     description,
        //     image,
        // });
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
            <Box
                sx={{
                    width: "90%",
                    maxWidth: "640px",
                    maxHeight: "80vh",
                    background: "#FFF",
                    borderRadius: "24px",
                    padding: "24px",
                    boxShadow: "3px 2px 20px 0px rgba(0, 0, 0, 0.04)",
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                        display: "none",
                    },
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        id="modal-title"
                        sx={{ fontSize: "20px", color: "#362B24", fontWeight: "700" }}
                    >
                        {editingData ? "Edit Notification" : "Create New Notification"}
                    </Typography>
                    <IconButton onClick={onClose}>
                        <img src={CrossIcon} alt="Cross" />
                    </IconButton>
                </Box>

                <ImageUploader
                    data="Upload Your Image"
                    onUpload={handleImageUpload}
                />

                {/* {isImageVisible && ( */}
                <Box sx={{ position: "relative", mt: 4 }}>
                    <img
                        src={grd}
                        alt="img"
                        style={{
                            width: "100px",
                            height: "87px",
                            borderRadius: "14px",
                            display: "block",
                        }}
                    />
                    <IconButton
                        onClick={handleRemoveClick}
                        sx={{
                            position: "absolute",
                            top: -9,
                            left: 82,
                            color: "white",
                            backgroundColor: "#EA001B",
                            "&:hover": {
                                backgroundColor: "#D1001A",
                            },
                            padding: 0,
                        }}
                    >
                        <CloseTwoToneIcon />
                    </IconButton>
                </Box>
                {/* )} */}

                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 3 }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: { xs: 2, small: 2, md: 3, lg: 5 },
                            flexWrap: "wrap",
                        }}
                    >
                        <Box sx={{ flex: 1 }}>
                            <InputLabel
                                htmlFor="dropdown"
                                sx={{
                                    mb: 1,
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    color: "#362B24",
                                    paddingLeft: "15px",
                                }}
                            >
                                Select Send to
                            </InputLabel>
                            <FormControl fullWidth>
                                <Select
                                    id="dropdown"
                                    value={sendTo}
                                    onChange={(e) => setSendTo(e.target.value)}
                                    sx={{
                                        borderRadius: "28px",
                                        border: "1.4px solid #362B24",
                                        opacity: 0.2,
                                        backgroundColor: "#FFF",
                                        width: "180px",
                                        height: "52px",
                                        flexShrink: 0,
                                    }}
                                >
                                    <MenuItem value="End User">End User</MenuItem>
                                    <MenuItem value="Vender">Vender</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>

                        <Box sx={{ flex: 1 }}>
                            <InputLabel
                                htmlFor="date-picker"
                                sx={{
                                    mb: 1,
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    color: "#362B24",
                                    paddingLeft: "15px",
                                }}
                            >
                                Notification Date
                            </InputLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    id="date-picker"
                                    value={notificationDate}
                                    onChange={(newValue) => setNotificationDate(newValue)}
                                    renderInput={(params) => (
                                        <input
                                            type="text"
                                            {...params.inputProps}
                                            style={{
                                                borderRadius: "28px",
                                                border: "1.4px solid #362B24",
                                                opacity: 0.2,
                                                backgroundColor: "#FFF",
                                                width: "180px",
                                                height: "52px",
                                                flexShrink: 0,
                                                padding: "0 16px",
                                            }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Box>

                        <Box sx={{ flex: 1 }}>
                            <InputLabel
                                htmlFor="time-picker"
                                sx={{
                                    mb: 1,
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    color: "#362B24",
                                    paddingLeft: "15px",
                                    cursor:"pointer"
                                }}
                            >
                                Enter Time
                            </InputLabel>
                            <input
                            
                                id="time-picker"
                                type="time"
                                value={time}
                                onChange={(e) => setTime(e.target.value)}
                                style={{
                                    borderRadius: "28px",
                                    border: "1.4px solid #362B24",
                                    opacity: 0.2,
                                    backgroundColor: "#FFF",
                                    width: "180px",
                                    height: "52px",
                                    flexShrink: 0,
                                    padding: "0 16px",
                                    boxSizing: "border-box",
                                     cursor:"pointer"
                                }}
                            />
                        </Box>
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: {xs:0,sm:2},
                        alignItems: "center",
                        mt:{xs:2,sm:3},
                        flexWrap: "wrap",
                    }}
                >
                    <div style={{ marginBottom: "16px", fontWeight: "bold" }}>
                        Type of Notification
                    </div>
                    <FormControl component="fieldset">
                        <RadioGroup
                            value={selectedType1}
                            onChange={handleChangesUser1}
                            row
                        >
                            <FormControlLabel
                                value="notification"
                                control={
                                    <Radio
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 28,
                                            color: 'transparent', 
                                        },
                                        '& .MuiRadio-root': {
                                            position: 'relative',
                                        },
                                        '&:before': {
                                            content: '""',
                                            position: 'absolute',
                                            width: 28,
                                            height: 28,
                                            borderRadius: '50%',
                                            border: '2px solid white', 
                                            backgroundColor: 'lightgrey', 
                                            left: '50%',
                                            top: '50%',
                                            transform: 'translate(-50%, -50%)',
                                        },
                                        '&.Mui-checked:before': {
                                            backgroundColor: 'green', 
                                        },
                                    }}
                                />
                                }
                                label="Notification"
                            />
                            <FormControlLabel
                                value="email"
                                control={
                                    <Radio
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 28,
                                            color: 'transparent', 
                                        },
                                        '& .MuiRadio-root': {
                                            position: 'relative',
                                        },
                                        '&:before': {
                                            content: '""',
                                            position: 'absolute',
                                            width: 28,
                                            height: 28,
                                            borderRadius: '50%',
                                            border: '2px solid white', 
                                            backgroundColor: 'lightgrey', 
                                            left: '50%',
                                            top: '50%',
                                            transform: 'translate(-50%, -50%)',
                                        },
                                        '&.Mui-checked:before': {
                                            backgroundColor: 'green', 
                                        },
                                    }}
                                />
                                }
                                label="Email"
                            />
                        </RadioGroup>
                    </FormControl>
                </Box>

                {selectedType1 === 'email' ? (
                        <>
                            <ReactQuill 
                            theme="snow" 
                            value={value} 
                            onChange={setValue}
                            style={{ 
                                height: '150px', 
                                marginBottom: '60px',
                                marginTop: "20px",
                                borderRadius: '18px'
                            }} 
                            />
                            <style jsx>{`
                            @media (max-width:480px) {
                                .ql-container {
                                height: 110px !important;  
                                }
                            }
                            `}</style>
                        </>
                ):(
                <>
            <div style={{ width: "100%", maxWidth: "624px", marginTop: "20px" }}>
                    <label
                        style={{
                            marginBottom: "8px",
                            textAlign: "left",
                            fontSize: "17px",
                            fontWeight: "600",
                            fontFamily: "Inter",
                            color: "#362B24",
                        }}
                    >
                        Notification Title
                    </label>
                    <input
                        type="text"
                        placeholder="Enter title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        style={{
                            width: "100%",
                            padding: "12px",
                            borderRadius: "28px",
                            border: "1.4px solid #362B24",
                            backgroundColor: "#FFF",
                            outline: 'none',
                            boxSizing: "border-box",
                            opacity: '0.6'
                        }}
                    />
                </div>

                <div style={{ width: "100%", maxWidth: "624px", marginTop: "20px" }}>
                    <label
                        style={{
                            marginBottom: "8px",
                            textAlign: "left",
                            fontSize: "17px",
                            fontWeight: "600",
                            fontFamily: "Inter",
                            color: "#362B24",

                        }}
                    >
                        Description
                    </label>
                    <textarea
                        placeholder="Enter Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        rows={4}
                        style={{
                            width: "100%",
                            padding: "12px",
                            height:'147px',
                            borderRadius: "28px",
                            border: "1.4px solid #362B24",
                            backgroundColor: "#FFF",
                            boxSizing: "border-box",
                            resize: "none",
                            outline: "none",
                            opacity: '0.6'
                        }}
                    />
                </div>
</>
)}
            
              



                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems:'center',
                        gap: {xs:0,sm:2},
                      
                        mt:{xs:2,sm:3},
                        flexWrap: "wrap",
                        
                    }}
                >
                    <div style={{ fontWeight: "bold" }}>
                        Send Notification
                    </div>
                    <FormControl component="fieldset">
                        <RadioGroup
                            value={selectedType}
                            onChange={handleChangesUser}
                            row
                        >
                            <FormControlLabel
                                value="alluser"
                                control={
                                    <Radio
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 28,
                                            color: 'transparent', 
                                        },
                                        '& .MuiRadio-root': {
                                            position: 'relative',
                                        },
                                        '&:before': {
                                            content: '""',
                                            position: 'absolute',
                                            width: 28,
                                            height: 28,
                                            borderRadius: '50%',
                                            border: '2px solid white', 
                                            backgroundColor: 'lightgrey', 
                                            left: '50%',
                                            top: '50%',
                                            transform: 'translate(-50%, -50%)',
                                        },
                                        '&.Mui-checked:before': {
                                            backgroundColor: 'green', 
                                        },
                                    }}
                                />
                            }
                                label="To all User"
                                fontSize="14px"
                            />
                            <FormControlLabel
                                value="selectedUser"
                                control={
                                    <Radio
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 28,
                                            color: 'transparent', 
                                        },
                                        '& .MuiRadio-root': {
                                            position: 'relative',
                                        },
                                        '&:before': {
                                            content: '""',
                                            position: 'absolute',
                                            width: 28,
                                            height: 28,
                                            borderRadius: '50%',
                                            border: '2px solid white', 
                                            backgroundColor: 'lightgrey', 
                                            left: '50%',
                                            top: '50%',
                                            transform: 'translate(-50%, -50%)',
                                        },
                                        '&.Mui-checked:before': {
                                            backgroundColor: 'green', 
                                        },
                                    }}
                                />
                            }
                                label="To Selected User"
                                fontSize="14px"
                            />
                        </RadioGroup>
                    </FormControl>
                </Box>



    {selectedType === 'selectedUser' && (
                <Box sx={{ display: "flex",justifyContent:'space-between',flexWrap:'wrap', alignItems: "center", gap: 1,mt:2 }}>
                   
                   <Box sx={{display:'flex',flexWrap:'wrap' ,gap:1}}>
                    <AvatarGroup
                        renderSurplus={(surplus) => (
                            <span>+{surplus.toString()[0]}k</span>
                        )}
                        total={4}
                        sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                    >
                        <Avatar
                            alt="Remy Sharp"
                            src={Adminimg}
                            sx={{ width: "25px", height: "25px" }}
                        />
                        <Avatar
                            alt="Travis Howard"
                            src={Adminimg}
                            sx={{ width: "25px", height: "25px" }}
                        />
                        <Avatar
                            alt="Agnes Walker"
                            src={Adminimg}
                            sx={{ width: "25px", height: "25px" }}
                        />
                        <Avatar
                            alt="Trevor Henderson"
                            src={Adminimg}
                            sx={{ width: "25px", height: "25px" }}
                        />
                   

                    </AvatarGroup>
                   

                    <Typography
                        sx={{
                            fontWeight: "400",
                            color: "#362B24",
                            fontFamily: "Heebo",
                            fontSize: { xs: '18px', sm: '18px' }
                        }}
                    >
                        2.4k Attendance
                    </Typography>

                    </Box>
                    <Button
                     onClick={handleSelectUserModal}
                        sx={{
                            mt:{xs:2,sm:0},
                            width: '145px',
                            height: '52px',
                            flexShrink: 0,
                            borderRadius: '28px',
                            background: 'linear-gradient(180deg, #065F46 0%, #0D7B5C 100%)',
                            boxShadow: '1px 2px 30px 0px rgba(0, 0, 0, 0.06)',
                            color: 'white',
                            textTransform:'none',
                            '&:hover': {
                                opacity: 0.8,
                            },
                        }}
                    >
                       Select User
                    </Button>
                </Box>
                )}







                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 2,
                        mt: 4,
                    }}
                >
                    <Button
                        variant="contained"
                        onClick={handleSave}
                        sx={{
                            borderRadius: "29px",
                            background: "linear-gradient(180deg, #065F46 0%, #0D7B5C 100%)",
                            maxWidth: "323px",
                            height: "57px",
                            width: "100%",
                            alignSelf: "center",
                            marginTop: "auto",
                            textTransform: "none",
                            fontFamily: "Heebo",
                            boxShadow: "1px 2px 30px 0px rgba(0, 0, 0, 0.06)",
                        }}
                    >
                        {editingData ? "Update Notification" : "Create Notification"}
                    </Button>

                </Box>

                    
     <Modal
                open={selctUserModal}
                onClose={handleSelectUserModal}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box
                    sx={{
                        width: "90%",
                        maxWidth: "635px",
                       
                        maxHeight: '80vh',
                     
                        bgcolor: '#FFF',
                        borderRadius: '32px',
                        boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.1)', 
                        p: 3, 
                        flexShrink: 0,
                       
                        overflowY: "auto",
                        "&::-webkit-scrollbar": {
                            display: "none",
                        },
                    }}
                >

                    <Box sx={{ display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',}}>
                     <Typography
                        id="modal-title"
                        sx={{ fontSize: "20px", color: "#362B24", fontWeight: "700" }}
                    >
                        {"Add New User"}
                    </Typography>
                    <IconButton onClick={handleCloseSelectedUserModal}>
                        <img src={CrossIcon} alt="Cross" />
                    </IconButton>
                    </Box>

            <Box mt={3}>
                    <TextField
                placeholder="Search..."
                sx={{
                  width: '100%',
                  height: { xs: 52, sm: 58 },
                  flexShrink: 0,
                  borderRadius: '33px',
                  background: '#EBEBEB',
                  border: 'none',
                  "& .MuiOutlinedInput-root": {
                    borderRadius: '33px',
                    height: '100%',
                    paddingLeft: '25px',
                    paddingRight: '10px',
                    outline: 'none',
                    border: 'none',
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 'none',
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: '#000', opacity: '0.35' }} />
                    </InputAdornment>
                  ),
                  style: { height: '100%' },
                }}
              />
                        
                    </Box>



                    <Box  display="flex" justifyContent="space-between" mt={3}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
                            <img src={Adminimg}/>
                            <div style={{color:'#362B24',fontWeight:'500'}}>Linda Grodan</div>
                        </Box>
                                <Button
                                variant="contained"
                                onClick={handleSave}
                                sx={{
                                    borderRadius: "29px",
                                    background: "linear-gradient(180deg, #065F46 0%, #0D7B5C 100%)",
                                    maxWidth: "94px",
                                    height: "41px",
                                    width: "100%",
                                    alignSelf: "center",
                                    marginTop: "auto",
                                    textTransform: "none",
                                    fontFamily: "Heebo",
                                    boxShadow: "1px 2px 30px 0px rgba(0, 0, 0, 0.06)",
                                }}
                            >
                                {"Select"}
                            </Button>
                    </Box>
                    <Box  display="flex" justifyContent="space-between" mt={3}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
                            <img src={Adminimg}/>
                            <div style={{color:'#362B24',fontWeight:'500'}}>Linda Grodan</div>
                        </Box>
                                <Button
                                variant="contained"
                                // onClick={handleSave}
                                sx={{
                                    borderRadius: "29px",
                                    background: "linear-gradient(180deg, #EA001B 0%, #EA001B 100%)",
                                    maxWidth: "94px",
                                    height: "41px",
                                    width: "100%",
                                    alignSelf: "center",
                                    marginTop: "auto",
                                    textTransform: "none",
                                    fontFamily: "Heebo",
                                    boxShadow: "1px 2px 30px 0px rgba(0, 0, 0, 0.06)",
                                }}
                            >
                                {"Reject"}
                            </Button>
                    </Box>
                    

                   <Box display="flex" justifyContent='center' mt={5}>

                        <Button
                                        variant="contained"
                                        onClick={handleSave}
                                        sx={{
                                            borderRadius: "29px",
                                            background: "linear-gradient(180deg, #065F46 0%, #0D7B5C 100%)",
                                            maxWidth: "358px",
                                            height: "59px",
                                            width: "100%",
                                            alignSelf: "center",
                                            marginTop: "auto",
                                            textTransform: "none",
                                            fontFamily: "Heebo",
                                            boxShadow: "1px 2px 30px 0px rgba(0, 0, 0, 0.06)",
                                        }}
                                    >
                                        {"Add Users"}
                            </Button>
                   </Box>

                </Box>

            </Modal>
        

            </Box>
        </Modal>
    );
};

export default NotificationModal;
