import React from 'react';
import { Box, Typography, TextField, Button, Modal, FormControlLabel, Checkbox, useMediaQuery, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CrossIcon from '../assests/croosicon.png';
import { styled } from '@mui/material/styles';

// Styled Checkbox component
const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  '& .MuiCheckbox-root': {
    color: '#065F46',
  },
  '& .Mui-checked': {
    color: '#065F46', 
  },
}));

const UserPermissionModal = ({ open, onClose, editingData, onSave, handleInputChange, handlePermissionChange }) => {
  const isSmallScreen = useMediaQuery('(max-width:480px)');
  const theme = useTheme();

  
  const data = editingData || {
    userName: '',
    Email: '',
    mono: '',
    password: '',
    retypePassword: '',
    permissions: []
  };

  // Ensure permissions is an array
  const permissions = Array.isArray(data.permissions) ? data.permissions : [];

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isSmallScreen ? '80%' : '550px',
        bgcolor: 'background.paper',
        borderRadius: '16px',
        boxShadow: 24,
        p: 4,
        maxHeight: '90vh',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          display: 'none' 
        }
      }}>

        <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <Typography id="modal-title" sx={{fontSize:'20px',color:'#362B24',fontWeight:'700'}}>
            {data.id ? 'Edit User Permission' : 'Add New Permission'}
          </Typography>
          <IconButton onClick={onClose}>
            <img src={CrossIcon} alt="Cross" style={{ height: isSmallScreen ? '20px' : '20px', width: isSmallScreen ? '20px' : '20px' }} />
          </IconButton>
        </Box>

        {/* User Name Field */}
        <Typography sx={{
          marginBottom: "8px",
          textAlign: "left",
          fontSize: { xs: "14px", sm: "17px" },
          fontWeight: "600",
          fontFamily: "Heebo",
          textIndent: "18px",
          marginTop:'25px'
        }}>
          User Name
        </Typography>
        <TextField
          fullWidth
          name="userName"
          value={data.userName}
          onChange={handleInputChange}
          placeholder="Type user name"
          sx={{
            borderRadius: "28px",
            border: "1px solid #362B24",
            '& .MuiOutlinedInput-root': {
              borderRadius: '25px',
            },
          }}
        />

        {/* Email Field */}
        <Typography sx={{
          marginBottom: "8px",
          textAlign: "left",
          fontSize: { xs: "14px", sm: "17px" },
          fontWeight: "600",
          fontFamily: "Heebo",
          textIndent: "18px",
          marginTop:'15px'
        }}>
          Email
        </Typography>
        <TextField
          fullWidth
          name="Email"
          type="email"
          value={data.Email}
          onChange={handleInputChange}
          placeholder="Type your email"
          sx={{
            borderRadius: "28px",
            border: "1px solid #362B24",
            '& .MuiOutlinedInput-root': {
              borderRadius: '25px',
            },
          }}
        />

        {/* Mobile Number Field */}
        <Typography sx={{
          marginBottom: "8px",
          textAlign: "left",
          fontSize: { xs: "14px", sm: "17px" },
          fontWeight: "600",
          fontFamily: "Heebo",
          textIndent: "18px",
          marginTop:'15px'
        }}>
          Mobile Number
        </Typography>
        <TextField
          fullWidth
          name="mono"
          type="text"
          value={data.mono}
          onChange={handleInputChange}
          placeholder="Type your mobile number"
          sx={{
            borderRadius: "28px",
            border: "1px solid #362B24",
            '& .MuiOutlinedInput-root': {
              borderRadius: '25px',
            },
          }}
        />

        {/* Password Field */}
        <Typography sx={{
          marginBottom: "8px",
          textAlign: "left",
          fontSize: { xs: "14px", sm: "17px" },
          fontWeight: "600",
          fontFamily: "Heebo",
          textIndent: "18px",
          marginTop:'15px'
        }}>
          Password
        </Typography>
        <TextField
          fullWidth
          name="password"
          type="password"
          value={data.password}
          onChange={handleInputChange}
          placeholder="Type your password"
          sx={{
            borderRadius: "28px",
            border: "1px solid #362B24",
            '& .MuiOutlinedInput-root': {
              borderRadius: '25px',
            },
          }}
        />

        {/* Retype Password Field */}
        <Typography sx={{
          marginBottom: "8px",
          textAlign: "left",
          fontSize: { xs: "14px", sm: "17px" },
          fontWeight: "600",
          fontFamily: "Heebo",
          textIndent: "18px",
          marginTop:'15px'
        }}>
          Retype Password
        </Typography>
        <TextField
          fullWidth
          name="retypePassword"
          type="password"
          value={data.retypePassword}
          onChange={handleInputChange}
          placeholder="Retype your password"
          sx={{
            borderRadius: "28px",
            border: "1px solid #362B24",
            '& .MuiOutlinedInput-root': {
              borderRadius: '25px',
            },
          }}
        />

        {/* User Permissions */}
        <Typography sx={{
          marginTop: "20px",
          marginBottom: "10px",
          fontSize: { xs: "14px", sm: "17px" },
          fontWeight: "600",
          fontFamily: "Heebo",
          marginLeft:'10px'
        }}>
          User Permissions
        </Typography>
        <Box sx={{marginLeft:'10px'}}>
          <FormControlLabel
            control={<CustomCheckbox checked={permissions.includes('Permission 1')} onChange={handlePermissionChange} value='Permission 1' />}
            label="Permission 1"
          />
          <br/>
          <FormControlLabel
            control={<CustomCheckbox checked={permissions.includes('Permission 2')} onChange={handlePermissionChange} value='Permission 2' />}
            label="Permission 2"
          />
          <br/>
          <FormControlLabel
            control={<CustomCheckbox checked={permissions.includes('Permission 3')} onChange={handlePermissionChange} value='Permission 3' />}
            label="Permission 3"
          />
        </Box>

        {/* Save Button */}
        <Box sx={{display:'flex',justifyContent:'center'}}>
          <Button
            onClick={() => onSave(data)}
            sx={{
              mt: 2,
              width: '323px',
              height: '57px',
              borderRadius: '28.5px',
              background: 'linear-gradient(180deg, #065F46 0%, #0D7B5C 100%)',
              color: 'white',
              textTransform: 'none',
              fontSize: '16px', 
              fontWeight: '600', 
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {data.id ? 'Update' : 'Add'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default UserPermissionModal;
