import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import backImg from "../../common/assests/backImg.png";
import TestingImg from "../../common/assests/gridimg3.png";
import { useLocation, useNavigate } from 'react-router-dom';
import './Vender.css'
import AllProductList from './AllProductList';
import NewAddedProduct from './NewAddedProduct';
import CreatedEvent from './CreatedEvent';
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import CallIcon from '@mui/icons-material/Call';

const VenderDetails = () => {
    

    const location = useLocation();
    const { selectedRow } = location.state || {};
    // console.log("Ss",selectedRow);
    
  const [activeComponent, setActiveComponent] = useState(0);

  const handleToggle = (index) => {
    setActiveComponent(index);
  };



    const navigate=useNavigate()

    const handleBack=()=>{
        navigate(-1)
    }
  return (
    <div style={{maxWidth:'1420px'}}>
      <Box sx={{ marginTop: '15px', paddingLeft: '10px'}}>
  <Typography
    sx={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "start",
      gap: '15px',
      width: "20%",
      alignItems: "center",
      cursor: "pointer",
    }}
    onClick={handleBack}
  >
    <img
      src={backImg}
      alt="backImg"
      style={{ height: "29px", width: "29px" }}
    />
    <Typography
      sx={{ color: "#065F46", fontWeight: "500", fontSize: "20px" }}
    >
      Back
    </Typography>
  </Typography>
</Box>

<Box
  sx={{
    borderRadius: '24px',
    backgroundColor: '#FFF',
    boxShadow: '3px 2px 20px 0px rgba(0, 0, 0, 0.04)',
    maxWidth: '1460px',
    width:'100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column', 
    padding: '16px',
    boxSizing: 'border-box',
    p:4,
    mt:'30px'
  }}
>
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center', 
      mb: '20px',
      flexWrap:'wrap',
      // gap:'10px'
      
    }}
  >
        <Typography 
      fontFamily="Heebo" 
      fontWeight={700} 
      color="#362B24"
      fontSize={20}
    >
      Vender Information
    </Typography>

<Box display="flex" flexWrap='wrap'gap={1} sx={{mt:{xs:'10px',sm:'0px',md:'0px'}}} >
  <Button
    sx={{
      borderRadius: '15px',
      backgroundColor: '#065F46',
      width: { xs: '70px',sm:'100px', md: '124px' }, 
      height: { xs: '40px', md: '50px' },   
      flexShrink: 0,
      fontSize:{xs:'10px',sm:'14px'},
      color: '#FFF',
      marginLeft: '8px',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#065F46',
      },
    }}
  >
    Edit Vendor
  </Button>
  <Button
    sx={{
      borderRadius: '15px',
      backgroundColor: '#EA001B',
      width: { xs: '70px',sm:'100px', md: '124px' },
      height: { xs: '40px', md: '50px' },   
      flexShrink: 0,
      color: '#FFF',
      marginLeft: '8px',
      textTransform: 'none',
      fontSize:{xs:'10px',sm:'14px'},
      '&:hover': {
        backgroundColor: '#EA001B',
      },
    }}
  >
    Delete
  </Button>
  <Button
    sx={{
      borderRadius: '15px',
      backgroundColor: '#362B24',
      width: { xs: '70px',sm:'100px', md: '124px' },
      height: { xs: '40px', md: '50px' },   
      flexShrink: 0,
      color: '#FFF',
      marginLeft: '8px',
      textTransform: 'none',
      fontSize:{xs:'10px',sm:'14px'},
      '&:hover': {
        backgroundColor: '#362B24',
      },
    }}
  >
    Block
  </Button>
</Box>

  </Box>

  {/* Grid layout  */}
 <Grid container alignItems="flex-start" gap={3} mt={5} mb={2}>
  {/* Single Row: Image and Text Column */}
  <Grid item xs={12} md={4}>
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        justifyContent: { xs: 'center',sm:'center', md: 'center',lg:'center',xl:'flex-start' },
        // mt: { xs: '10px',sm:'10px' },
        textAlign: { xs: 'center', md: 'left' },
        flexWrap:'wrap'
      }}
    >
      <Box
        component="img"
        src={TestingImg}
        alt="user img"
        sx={{
          objectFit: 'cover',
          borderRadius: '50%',
          height: '124px',
          width: '124px',
          mr: { lg:3,xl: 3, xs: 0 },
          mb: { xs: 2, md: 0 },
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap:'wrap',
          marginTop:{md:'25px',xl:'0px'},
          alignItems: { xs: 'center',sm:'center', md: 'center', lg: 'center',xl:'flex-start' },
          gap: '5px',
        }}
      >
        <Typography sx={{ fontSize: '20px', fontWeight: '600', color: '#362B24' }}>
          Business Name
        </Typography>
        <Typography sx={{ color: '#065F46', display: 'flex', alignItems: 'center', mt: 1 }}>
          <EmailRoundedIcon sx={{ mr: 1 }} />
          abcde123@gmail.com
        </Typography>
        <Typography sx={{ color: '#065F46', display: 'flex', alignItems: 'center', mt: 1 }}>
          <CallIcon sx={{ mr: 1 }} />
          (+41) 789 441 6542
        </Typography>
      </Box>
    </Box>
  </Grid>

  {/* Second Column */}
  <Grid item xs={12} md={6} >
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      {/* First Grid Row */}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs:'repeat(2, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)',lg: 'repeat(4, 1fr)',xl:'repeat(5, 1fr)' },
          gap: '16px',
          mt:{sm:'20px',md:'0px'}
        }}
      >
        <Box>
          <Typography sx={{ fontWeight: '400', opacity: '0.6', color: '#362B24' }}>Status</Typography>
          <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>
            <strong>Active</strong>
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: '400', opacity: '0.6', color: '#362B24' }}>Created Date</Typography>
          <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>
            <strong>15/08/23</strong>
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: '400', opacity: '0.6', color: '#362B24' }}>Bank Detail</Typography>
          <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>
            <strong>Filled</strong>
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: '400', opacity: '0.6', color: '#362B24' }}>Total Balance</Typography>
          <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>
            <strong>$356.00</strong>
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: '400', opacity: '0.6', color: '#362B24' }}>Bank Name</Typography>
          <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>
            <strong>Central bank of America</strong>
          </Typography>
        </Box>
      </Box>

      {/* Second Grid Row */}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {  xs:'repeat(2, 1fr)', sm: 'repeat(3, 1fr)' },
          gap: '16px',
        }}
      >
        <Box>
          <Typography sx={{ fontWeight: '400', opacity: '0.6', color: '#362B24' }}>Account No</Typography>
          <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>
            <strong>**********8956</strong>
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: '400', opacity: '0.6', color: '#362B24' }}>Account Type</Typography>
          <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>
            <strong>Saving</strong>
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: '400', opacity: '0.6', color: '#362B24' }}>Address</Typography>
          <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>
            <strong>23, Lorem ipsom is simple, oplet</strong>
          </Typography>
        </Box>
      </Box>

      {/* Third Grid Row */}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {  xs:'repeat(2, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' },
          gap: '16px',
          // mt:{md:'10px',lg:'10px'}
        }}
      >
        <Box>
          <Typography sx={{ fontWeight: '400', opacity: '0.6', color: '#362B24' }}>City</Typography>
          <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>
            <strong>Taxes</strong>
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: '400', opacity: '0.6', color: '#362B24' }}>State</Typography>
          <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>
            <strong>Taxes</strong>
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: '400', opacity: '0.6', color: '#362B24' }}>Country</Typography>
          <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>
            <strong>USA</strong>
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: '400', opacity: '0.6', color: '#362B24' }}>Zip Code</Typography>
          <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>
            <strong>400 008</strong>
          </Typography>
        </Box>
      </Box>
    </Box>
  </Grid>
</Grid>


</Box>





      

<div className="vendor-detail-toggle-container d-flex justify-content-between align-items-center flex-wrap mt-4">
  <div className="vendor-detail-button-box">
    <div
      id="vendor-detail-btn"
      style={{ 
        left: `${activeComponent * 32.5}%`, 
        width: `33.33%` 
      }}
    ></div>
    <button
      className={`vendor-detail-toggle-btn ${activeComponent === 0 ? 'active' : ''}`}
      onClick={() => handleToggle(0)}
    >
     All Product list
    </button>
    <button
      className={`vendor-detail-toggle-btn ${activeComponent === 1 ? 'active' : ''}`}
      onClick={() => handleToggle(1)}
    >
     New Added Product
    </button>
    <button
      className={`vendor-detail-toggle-btn ${activeComponent === 2 ? 'active' : ''}`}
      onClick={() => handleToggle(2)}
    >
      Created Events
    </button>
  </div>
</div>


{activeComponent === 0 && <AllProductList />}
{activeComponent === 1 && <NewAddedProduct />}
{activeComponent === 2 && <CreatedEvent />}
     


    
   
    </div>
  )
}

export default VenderDetails