import React from 'react'
import { Box, Typography, TextField, Button, Modal, FormControlLabel, Checkbox, useMediaQuery, IconButton, InputAdornment } from '@mui/material';
import ImageUploader from '../imguploader/ImageUploader';

const EditProfile = ({onClose}) => {

    const onSave=()=>{
        onClose()
    }
  return (
    <div>

      <ImageUploader data="Upload Profile Image"/>
          <Typography sx={{
          marginBottom: "8px",
          textAlign: "left",
          fontSize: { xs: "14px", sm: "17px" },
          fontWeight: "600",
          fontFamily: "Heebo",
          textIndent: "18px",
          marginTop:'25px'
        }}>
          Name
        </Typography>
        <TextField
                fullWidth
                type="text"
                name="name"
                placeholder="Type your Name"
                sx={{
                  borderRadius: "28px",
                  opacity: "0.3",
                  border: "1px solid #362B24",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
              
              
              />

        {/* Email Field */}
        <Typography sx={{
          marginBottom: "8px",
          textAlign: "left",
          fontSize: { xs: "14px", sm: "17px" },
          fontWeight: "600",
          fontFamily: "Heebo",
          textIndent: "18px",
          marginTop:'15px'
        }}>
          Email
        </Typography>
        <TextField
                fullWidth
                type="email"
                name="Email"
                placeholder="Type your Email"
                sx={{
                  borderRadius: "28px",
                  opacity: "0.3",
                  border: "1px solid #362B24",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
              
                
              />

        {/* Mobile Number Field */}
        <Typography sx={{
          marginBottom: "8px",
          textAlign: "left",
          fontSize: { xs: "14px", sm: "17px" },
          fontWeight: "600",
          fontFamily: "Heebo",
          textIndent: "18px",
          marginTop:'15px'
        }}>
          Mobile No
        </Typography>
        <TextField
                fullWidth
                type="text"
                name="mono"
                placeholder="Type your Number"
                sx={{
                  borderRadius: "28px",
                  opacity: "0.3",
                  border: "1px solid #362B24",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
              
               
              />

<Box sx={{display:'flex',justifyContent:'center',marginTop:'20px'}}>
          <Button
            onClick={() => onSave()}
            sx={{
              mt: 2,
              width: '337px',
              height: '57px',
              borderRadius: '28.5px',
              background: 'linear-gradient(180deg, #065F46 0%, #0D7B5C 100%)',
              color: 'white',
              textTransform: 'none',
              fontSize: '16px', 
              fontWeight: '600', 
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            Save
          </Button>
        </Box>
        

    </div>
  )
}

export default EditProfile