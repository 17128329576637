import React, { useState } from 'react';
import TotalSales from './TotalSales';
import './Report.css';
import { Box } from '@mui/material';
import PopularProduct from './PopularProduct';

const Report = () => {
  

  const [activeComponent, setActiveComponent] = useState(0);

  const handleToggle = (index) => {
    setActiveComponent(index);
  };


  return (
    <>
      <Box sx={{ marginTop: 1, display: 'flex', justifyContent: 'start' }}>
      <div className="custom-total-sales-container d-flex justify-content-between align-items-center flex-wrap ">
  
  <div className="custom-button-box">
    <div
      id="custom-btn"
      style={{ 
        left: `${activeComponent *50}%`, 
        width: `47%` 
      }}
    ></div>
    <button
      className={`custom-toggle-btn ${activeComponent === 0 ? 'active' : ''}`}
      onClick={() => handleToggle(0)}
    >
      TotalSales
    </button>
    <button
      className={`custom-toggle-btn ${activeComponent === 1 ? 'active' : ''}`}
      onClick={() => handleToggle(1)}
    >
      Popular Product
    </button>
  </div>
</div>

      </Box>

      {activeComponent === 0 && <TotalSales />}
      {activeComponent === 1 && <PopularProduct />}
    </>
  );
};

export default Report;
