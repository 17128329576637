import React, { useState } from 'react';
import backImg from "../assests/backImg.png";
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, useMediaQuery, useTheme, Box, Typography, IconButton, Button, TableHead } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '../assests/addmsglogo.png';
import EditIcon from '../assests/editicon.png';
import LockIcon from '../assests/lockicons.png';
import DeleteIcon from '../assests/deleticon.png';
import CrossIcon from '../assests/croosicon.png';
import UserPermissionModal from './UserPermissionModal'; 
import Paginations from '../pagination/Paginations';

const UserPermission = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery('(max-width:480px)');
  const [open, setOpen] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [rows, setRows] = useState([
    { id: 1, userName: 'ABC', Email: 'abc@gmail.com', mono: '123456789', status: 'Active', CreatedDate: '2024-08-09' },
   
  ]);

  const handleSave = (data) => {
    if (data.userName && data.Email) {
      setRows((prevRows) => {
        if (data.id) {
          // Update existing row
          return prevRows.map((row) =>
            row.id === data.id ? data : row
          );
        } else {
          // Add new row
          return [
            ...prevRows,
            { ...data, id: prevRows.length + 1, CreatedDate: new Date().toISOString().split('T')[0], status: 'Pending', mono: '123456789' }
          ];
        }
      });
    }
    handleClose();
  };

  const handleClickOpen = (data) => {
    setEditingData(data ? { ...data } : { userName: '', Email: '', mono: '', password: '', retypePassword: '', permissions: [] });
    setOpen(true);
  };

  const handleBack = () => {
    navigate('/dashboard');
  };

  const handleClose = () => {
    setOpen(false);
    setEditingData(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditingData({ ...editingData, [name]: value });
  };

  const handlePermissionChange = (event) => {
    const { value, checked } = event.target;
    const updatedPermissions = checked 
      ? [...(editingData.permissions || []), value] 
      : (editingData.permissions || []).filter(permission => permission !== value);
    
    setEditingData({ ...editingData, permissions: updatedPermissions });
  };


  return (
    <div>
      <Box sx={{ marginTop: '15px', paddingLeft: '10px'}}>
        <Typography
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            gap: '15px',
            width: "20%",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={handleBack}
        >
          <img
            src={backImg}
            alt="backImg"
            style={{ height: "29px", width: "29px" }}
          />
          <Typography
            sx={{ color: "#065F46", fontWeight: "500", fontSize: "20px" }}
          >
            Back
          </Typography>
        </Typography>
      </Box>

      <Box sx={{ marginTop: '40px',marginBottom:'30px' }}>
        <TableContainer component={Paper} sx={{ padding: '26px', borderRadius: '24px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
            <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>All Users Permissions</Typography>
            <Button
              onClick={() => handleClickOpen(null)}
              sx={{
                borderRadius: '29px',
                background: 'linear-gradient(180deg, #065F46 0%, #0D7B5C 100%)',
                boxShadow: '1px 2px 30px 0px rgba(0, 0, 0, 0.06)',
                width: '235px',
                height: '58px',
                color: 'white',
                display: 'flex',
                gap: '5px',
                textTransform: 'none',
                fontSize: isSmallScreen ? '12px' : '16px',
                '@media (max-width:600px)': {
                  width: '200px',
                  height: '50px',
                },
              }}
            >
              <img src={AddIcon} alt="Add" style={{ height: '24px', width: '24px', marginRight: '3px' }} />
              Add New Permission
            </Button>
          </Box>

          <Table sx={{ minWidth: 650 }} aria-label="custom table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: '#362B24', fontWeight: '600', fontFamily: 'Heebo', fontSize: '16px' }}>Name</TableCell>
                <TableCell sx={{ color: '#362B24', fontWeight: '600', fontFamily: 'Heebo', fontSize: '16px' }}>Email</TableCell>
                <TableCell sx={{ color: '#362B24', fontWeight: '600', fontFamily: 'Heebo', fontSize: '16px' }}>Mono</TableCell>
                <TableCell sx={{ color: '#362B24', fontWeight: '600', fontFamily: 'Heebo', fontSize: '16px' }}>Status</TableCell>
                <TableCell sx={{ color: '#362B24', fontWeight: '600', fontFamily: 'Heebo', fontSize: '16px' }}>Created Date</TableCell>
                <TableCell sx={{ color: '#362B24', fontWeight: '600', fontFamily: 'Heebo', fontSize: '16px' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>{row.userName}</TableCell>
                  <TableCell>{row.Email}</TableCell>
                  <TableCell>{row.mono}</TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>{row.CreatedDate}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleClickOpen(row)}>
                      <img src={EditIcon} alt="Edit" style={{ height: isSmallScreen ? '35px' : '45px', width: isSmallScreen ? '35px' : '45px' }} />
                    </IconButton>
                    <IconButton onClick={() => console.log('Lock clicked')}>
                      <img src={LockIcon} alt="Lock" style={{ height: isSmallScreen ? '35px' : '45px', width: isSmallScreen ? '35px' : '45px' }} />
                    </IconButton>
                    <IconButton onClick={() => console.log('Delete clicked')}>
                      <img src={DeleteIcon} alt="Delete" style={{ height: isSmallScreen ? '35px' : '45px', width: isSmallScreen ? '35px' : '45px' }} />
                    </IconButton>
                    
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Paginations/>
        </TableContainer>
      </Box>

      <UserPermissionModal
        open={open}
        onClose={handleClose}
        editingData={editingData}
        onSave={handleSave}
        handleInputChange={handleInputChange}
        handlePermissionChange={handlePermissionChange}
      />
    </div>
  );
};

export default UserPermission;
