import React, { useState } from 'react'
import AllVendor from './AllVendor';
import PendingApprove from './PendingApprove';
import './Vender.css'
const VendorManagement = () => {

  const [activeComponent, setActiveComponent] = useState(0);

  const handleToggle = (index) => {
    setActiveComponent(index);
  };
  return (
    <div>
          <div className="custom-total-sales-container d-flex justify-content-between align-items-center flex-wrap ">
  
  <div className="custom-button-box">
    <div
      id="custom-btn"
      style={{ 
        left: `${activeComponent *50}%`, 
        width: `47%` 
      }}
    ></div>
    <button
      className={`custom-toggle-btn ${activeComponent === 0 ? 'active' : ''}`}
      onClick={() => handleToggle(0)}
    >
     All Vender
    </button>
    <button
      className={`custom-toggle-btn ${activeComponent === 1 ? 'active' : ''}`}
      onClick={() => handleToggle(1)}
    >
     Pending Approve
    </button>
  </div>
</div>


{activeComponent === 0 && <AllVendor />}
{activeComponent === 1 && <PendingApprove />}
    </div>
  )
}

export default VendorManagement