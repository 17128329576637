import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Typography,
  InputAdornment,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";
import companylogo from "../../common/assests/companylogo.png";
import emailIcon from "../../common/assests/emailicon.png";
import passwordIcon from "../../common/assests/password.png";

import "./SignUp.css";
import ForgotPassword from "../forgot/ForgotPassword";
import OtpModal from "../forgot/OtpModal";
import ConfirmPassModal from "../forgot/ConfirmPassModal";
import { useNavigate } from "react-router-dom";

const SignUp = () => {


  const navigate = useNavigate();

  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const [openOtp, setOpenOtp] = useState(false);
  const [openNewPassword, setOpenNewPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleForgotPasswordClickOpen = () => setOpenForgotPassword(true);
  const handleForgotPasswordClose = () => setOpenForgotPassword(false);

  const handleOtpClickOpen = () => {
    setOpenForgotPassword(false);
    setOpenOtp(true);
  };
  const handleOtpClose = () => setOpenOtp(false);

  const handleNewPasswordClickOpen = () => {
    setOpenOtp(false);
    setOpenNewPassword(true);
  };
  const handleNewPasswordClose = () => setOpenNewPassword(false);

  const handleSubmitNewPassword = () => {
    setOpenNewPassword(false);
  };

  const handleBackToForgotPassword = () => {
    setOpenOtp(false);
    setOpenForgotPassword(true);
  };

  const handleBackToOtp = () => {
    setOpenNewPassword(false);
    setOpenOtp(true);
  };

  const handleSignIn = () => {
    navigate('/dashboard')
  }
  return (
    <div className="outer">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          padding: "16px",
        }}
      >
        <StyledCard>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "30px",
              }}
            >
              <img
                src={companylogo}
                alt="logo"
                className="img-fluid"
                style={{ height: "160px", width: "143px" }}
              />
            </Box>

            <Box
              sx={{
                paddingRight: {
                  xs: "3px",
                  sm: "16px",
                  md: "24px",
                  lg: "34px",
                  xl: "34px",
                },
                textAlign: "left",
              }}
            >
              <Typography
                sx={{
                  color: "#362B24",
                  fontWeight: "700",
                  marginTop: "45px",
                  fontFamily: "Heebo",
                  fontSize: "24px",
                  lineHeight: "24px",
                }}
              >
                Hello, Welcome Back
              </Typography>

              <Typography
                sx={{
                  color: "#362B24",
                  fontWeight: "400",
                  marginTop: "15px",
                  fontFamily: "Heebo",
                  fontSize: "16px",
                  lineHeight: "22px",
                  opacity: "0.5",
                }}
              >
                Lorem ipsom is simple dummy text that can be
              </Typography>
            </Box>

            <Box sx={{ width: "90%", marginTop: "40px" }}>
              <Typography
                sx={{
                  marginBottom: "8px",
                  textAlign: "left",
                  marginLeft: "20px",
                  fontSize: "17px",
                  fontWeight: "600",
                  fontFamily: "Heebo",
                }}
              >
                Email
              </Typography>
              <TextField
                fullWidth
                type="email"
                placeholder="Type your email"
                sx={{
                  borderRadius: "28px",
                  opacity: "0.3",
                  border: "1px solid #362B24",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={emailIcon}
                        alt="email-logo"
                        className="img-fluid"
                        style={{ width: "20px" }}
                      />
                    </InputAdornment>
                  ),
                  style: { borderRadius: "28px" },
                }}
              />
            </Box>
            <Box sx={{ width: "90%", margin: "12px" }}>
              <Typography
                sx={{
                  marginBottom: "8px",
                  textAlign: "left",
                  marginLeft: "20px",
                  fontSize: "17px",
                  fontWeight: "600",
                  fontFamily: "Heebo",
                }}
              >
                Password
              </Typography>
              <TextField
                fullWidth
                type="password"
                placeholder="Type your Password"
                sx={{
                  borderRadius: "28px",
                  opacity: "0.3",
                  border: "1px solid #362B24",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={passwordIcon}
                        alt="password-logo"
                        className="img-fluid"
                        style={{ width: "20px" }}
                      />
                    </InputAdornment>
                  ),
                  style: { borderRadius: "28px" },
                }}
              />
            </Box>

            <Box
              sx={{
                marginRight: { xs: "25px", sm: "35px", md: "35px" },
                width: "90%",
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              <Typography
                sx={{
                  color: "#065F46",
                  fontFamily: "Heebo",
                  fontSize: "17px",
                  fontWeight: "700",
                  textAlign: "right",
                  cursor: "pointer",
                }}
                onClick={handleForgotPasswordClickOpen}
              >
                Forgot Password?
              </Typography>
            </Box>

            <Box
              sx={{
                padding: "12px",
                width: "90%",
                marginTop: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                sx={{
                  width: "100%",
                  maxWidth: "410px",
                  height: "60px",
                  flexShrink: 0,
                  borderRadius: "30px",
                  background:
                    "linear-gradient(180deg, #065F46 0%, #0D7B5C 100%)",
                  boxShadow: "1px 2px 30px 0px rgba(0, 0, 0, 0.06)",
                  color: "#FFF",
                  textAlign: "center",
                  fontFamily: "Heebo",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "13px",
                  textTransform: "none",
                }}
                onClick={handleSignIn}
              >
                Sign in
              </Button>
            </Box>
          </CardContent>
        </StyledCard>
      </Box>

      <ForgotPassword
        open={openForgotPassword}
        onClose={handleForgotPasswordClose}
        onNext={handleOtpClickOpen}
        email={email}
        setEmail={setEmail}
      />

      <OtpModal
        open={openOtp}
        onClose={handleOtpClose}
        onNext={handleNewPasswordClickOpen}
        onBack={handleBackToForgotPassword}
        otp={otp}
        setOtp={setOtp}
      />

      <ConfirmPassModal
        open={openNewPassword}
        onClose={handleNewPasswordClose}
        onSubmit={handleSubmitNewPassword}
        onBack={handleBackToOtp}
        newPassword={newPassword}
        setNewPassword={setNewPassword}
        confirmPassword={confirmPassword}
        setConfirmPassword={setConfirmPassword}
      />
    </div>
  );
};

const StyledCard = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: "480px",
  borderRadius: "32px",
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  [theme.breakpoints.down("sm")]: {
    minHeight: "auto",
  },
}));

export default SignUp;
