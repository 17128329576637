import React, { useState } from 'react';
import { Box, Typography, Modal, IconButton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CrossIcon from '../assests/croosicon.png'; 
import './UserProfile.css';
import EditProfile from './EditProfile';
import ChangePassword from './ChangePassword';

const UserProfile = ({ open, onClose }) => {
  const isSmallScreen = useMediaQuery('(max-width:480px)');
  const theme = useTheme();

  const [activeComponent, setActiveComponent] = useState(0);



  const handleToggle = (index) => {
    setActiveComponent(index);
  };

  const handleCloseModal = () => {
    setActiveComponent(0); 
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isSmallScreen ? '80%' : '550px',
        bgcolor: 'background.paper',
        borderRadius: '16px',
        boxShadow: 24,
        p: 4,
        maxHeight: '90vh',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          display: 'none'
        }
      }}>
        <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <Typography id="modal-title" sx={{fontSize:'20px',color:'#362B24',fontWeight:'700'}}>
            Manage Profile
          </Typography>
          <IconButton onClick={handleCloseModal}>
            <img src={CrossIcon} alt="Cross" style={{ height: isSmallScreen ? '20px' : '20px', width: isSmallScreen ? '20px' : '20px' }} />
          </IconButton>
        </Box>

      

<Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'center' }}>
  <div className="userprofile-total-sales-container d-flex justify-content-between align-items-center flex-wrap">
    <div className="userprofile-button-box">
      <div
        id="userprofile-btn"
        style={{
          left: `${activeComponent * 50}%`,
          width: `46%`,
        }}
      ></div>
      <button
        className={`userprofile-toggle-btn ${activeComponent === 0 ? 'active' : ''}`}
        onClick={() => handleToggle(0)}
      >
        Edit Profile
      </button>
      <button
        className={`userprofile-toggle-btn ${activeComponent === 1 ? 'active' : ''}`}
        onClick={() => handleToggle(1)}
      >
        Change Password
      </button>
    </div>
  </div>
</Box>





        <Box sx={{ marginTop: 5 }}>
          {activeComponent === 0 && <EditProfile onClose={handleCloseModal} />}
          {activeComponent === 1 && <ChangePassword onClose={handleCloseModal} />}
        </Box>
      </Box>
    </Modal>
  );
};

export default UserProfile;
