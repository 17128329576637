// import React from 'react'

// const UserManagement = () => {
//   return (
//     <div>UserManagement</div>
//   )
// }

// export default UserManagement


import React, { useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  useMediaQuery,
  useTheme,
  Box,
  Typography,
  IconButton,
  Button,
  TableHead,
  Modal,
} from "@mui/material";
import EyeIcon from "../../common/assests/eyegreen.png";
import LockIcon from "../../common/assests/lockicons.png";
import DeletIcon from "../../common/assests/deleticon.png";
import Adminimg from "../../common/assests/adminimg.png";
// import './Vender.css'

import Paginations from "../../common/pagination/Paginations";
import { useNavigate } from 'react-router-dom';
const UserManagement = () => {
  const isSmallScreen = useMediaQuery("(max-width:480px)")
  const [rows, setRows] = useState([
    {
      id: 1,
      userName: "User Name",
      userImage: Adminimg,
      businessName: "Business A",
      businessImage: Adminimg,
      Email: "abc@gmail.com",
      mono: "(+41) 789 441 6542",
      street: "123 Street",
      address:"US",
      city: "abc",
      state: "xyz",
      zipcode: "12345",
      country: "America",
      totalPrice: "$100",
      quantity: 5,
      paymentStatus: "Paid",
      status: "Active",
      CreatedDate: "2024-08-09",
      bankdetail:"filled",
      bio:'Bio',
    },
    {
      id: 2,
      userName: "User Namess",
      userImage: Adminimg,
      businessName: "Business A",
      businessImage: Adminimg,
      Email: "abc@gmail.com",
      mono: "(+41) 789 441 6542",
      street: "123 Street",
      address:"London",
      city: "abcd",
      state: "xyzw",
      zipcode: "1266665",
      country: "Canada",
      totalPrice: "$100",
      quantity: 5,
      paymentStatus: "Paid",
      status: "Active",
      CreatedDate: "2024-08-09",
      bankdetail:"filled",
      bio:'Bio',
    },
  ]);
  const [open, setOpen] = useState(false);

  const [selectedRow, setSelectedRow] = useState(null);
  const navigate=useNavigate();

  const handleClickOpen = (row) => {
    navigate('/user-management/user-information', {
      state: { selectedRow: row } 
  });
    setSelectedRow(row);
    setOpen(true);
  };

 


  return (
    <div>

<Box sx={{ marginTop: "40px", marginBottom: "30px" }}>
       <TableContainer
          component={Paper}
          sx={{ padding: "26px", borderRadius: "24px" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="custom table">
            <TableHead>
              <TableRow  >
                <TableCell
               className='allvender'
                >
                   Name
                </TableCell>
                <TableCell
                 className='allvender'
                >
                  Bio
                </TableCell>
                <TableCell
                 className='allvender'
                >
                  Email
                </TableCell>
                <TableCell
                className='allvender'
                >
                  Mobile no
                </TableCell>
                
               
                <TableCell
                className='allvender'
                >
                  Address
                </TableCell>
               
                <TableCell
                className='allvender'
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={row.userImage}
                        alt={row.userName}
                        style={{
                          height: "45px",
                          width: "45px",
                          marginRight: "8px",
                          borderRadius: "50%",
                        }}
                      />
                      <Typography>{row.userName}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{row.bio}</TableCell>
                  <TableCell>{row.Email}</TableCell>
                  <TableCell>{row.mono}</TableCell>
                 
                  <TableCell>{row.address}</TableCell>
                 
                  <TableCell
                    sx={{
                      display: "flex",
                      justifyContent:'flex-start',
                      alignItems: "center",
                     
                    }}
                  >
                    <IconButton onClick={() => handleClickOpen(row)}>
                      <img
                        src={EyeIcon}
                        alt="viewicon"
                        style={{
                          height: isSmallScreen ? "35px" : "42px",
                          width: isSmallScreen ? "35px" : "45px",
                        }}
                      />
                    </IconButton>
                    <IconButton >
                      <img
                        src={LockIcon}
                        alt="viewicon"
                        style={{
                          height: isSmallScreen ? "35px" : "42px",
                          width: isSmallScreen ? "35px" : "45px",
                        }}
                      />
                    </IconButton>
                    <IconButton >
                      <img
                        src={DeletIcon}
                        alt="viewicon"
                        style={{
                          height: isSmallScreen ? "35px" : "42px",
                          width: isSmallScreen ? "35px" : "45px",
                        }}
                      />
                    </IconButton>
                   
                  </TableCell>
                </TableRow>
              ))}
              
            </TableBody>
           
          </Table>

          <Paginations/>

        </TableContainer>
      </Box>

    </div>
  )
}

export default UserManagement