import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Modal, IconButton } from '@mui/material';
import ImageUploader from '../../common/imguploader/ImageUploader';
import grd from '../../common/assests/gridimg3.png'
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import CrossIcon from '../../common/assests/croosicon.png';

const ForumModal = ({ open, handleClose, handleSave, editingData, }) => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
  });

  useEffect(() => {
    if (editingData) {
      setFormData(editingData);
    } else {
      setFormData({
        title: '',
        description: '',
      });
    }
  }, [editingData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onSave = () => {
    handleSave(formData);
    // handleClose();
    handleClose()
  };
  const [isImageVisible, setIsImageVisible] = useState(true);

  const handleRemoveClick = () => {
    setIsImageVisible(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box
        sx={{
          width: '90%',
          maxWidth: '600px',
          maxHeight:'70vh',
          background: '#FFF',
          borderRadius: '24px',
          padding: '24px',
          boxShadow: '3px 2px 20px 0px rgba(0, 0, 0, 0.04)',
          overflowY:'auto',
          '&::-webkit-scrollbar': {
            display: 'none' 
          }
        }}
      >
        <Typography
          sx={{
            fontSize: '20px',
            fontWeight: '600',
            marginBottom: '16px',
            fontFamily: 'Heebo',
          }}
        >
          <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <Typography id="modal-title" sx={{fontSize:'20px',color:'#362B24',fontWeight:'700'}}>
          {editingData ? 'Edit Forum' : 'Create New Forum'} 
          </Typography>
          <IconButton onClick={handleClose}>
            <img src={CrossIcon} alt="Cross"  />
          </IconButton>
        </Box>
        </Typography>

        <ImageUploader data="Upload Product Image" />
        {/* Form Title Field */}

        {isImageVisible && (
        <Box sx={{ position: 'relative', mt: 4 }}>
          <img
            src={grd}
            alt='img'
            style={{
              width: '100px',
              height: '87px',
              borderRadius: '14px',
              display: 'block',
            }}
          />
          <IconButton
            onClick={handleRemoveClick}
            sx={{
              position: 'absolute',
              top: -9,
            //   right: "443px",
              left:82,
              color: 'white',
              backgroundColor: '#EA001B',
              '&:hover': {
                backgroundColor: '#D1001A',
              },
              padding: 0, 
            }}
          >
            <CloseTwoToneIcon />
          </IconButton>
        </Box>
      )}
        <Box sx={{ width: '100%', marginBottom: '16px',mt:4 }}>
          <Typography
            sx={{
              marginBottom: '8px',
              textAlign: 'left',
              fontSize: '17px',
              fontWeight: '600',
              fontFamily: 'Heebo',
            }}
          >
            Forum title
          </Typography>

      <input
        type="text"
        placeholder='Type here'
        value={formData.title}
         name="title"
            onChange={handleChange}
        style={{
          borderRadius: '28px',
          border: '1.4px solid #362B24',
          opacity: 0.6,
          backgroundColor: '#FFF',
          width: '100%',
          height: '52px',
          flexShrink: 0,
          padding: '0 16px',
          boxSizing: 'border-box',
          outline:'none',
          color:'#000'
          
        }}

      />
        </Box>
        {/* Form Description Field */}
        <Box sx={{ width: '100%', marginBottom: '16px' }}>
          <Typography
            sx={{
              marginBottom: '8px',
              textAlign: 'left',
              fontSize: '17px',
              fontWeight: '600',
              fontFamily: 'Heebo',
            }}
          >
            Enter Description
          </Typography>
           <textarea
            placeholder="Type here"
            name="description"
            value={formData.description}
            onChange={handleChange}
            style={{
              maxWidth: '580px',
              width:'100%',
              height: '180px',
              borderRadius: '18px',
              border: '1.4px solid #362B24',
              opacity: 0.6,
              backgroundColor: '#FFF',
              padding: '16px',
              fontSize: '16px',
              resize: 'none', 
              marginTop:'15px',
              outline:"none",
              color:'black'
            }}
          />
        </Box>

        <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',mt:3}}>
            <Button
          variant="contained"
          onClick={onSave}
          sx={{
            borderRadius: '29px',
            background: 'linear-gradient(180deg, #065F46 0%, #0D7B5C 100%)',
            maxWidth: '323px',
            height:'57px',
            width:'100%',
            alignSelf: 'center',
            marginTop: 'auto',
            textTransform:'none',
            fontFamily:'Heebo',
            boxShadow: "1px 2px 30px 0px rgba(0, 0, 0, 0.06)"
          }}
        >
          {editingData ? 'Update' : 'Create Forum'}
        </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ForumModal;
