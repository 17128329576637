import React from 'react'
import backImg from "../assests/backImg.png";
import { Card, CardContent, Table, TableBody, TableCell, TableContainer, TableRow, Paper, useMediaQuery, useTheme, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TableHead from '@mui/material/TableHead';
import Paginations from '../pagination/Paginations';

const PaymentInfo = () => {
    
    const navigate=useNavigate();
 const theme = useTheme();
    
 const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleBack=()=>{
        navigate('/dashboard')
    }


    
    function createData(userName, venderName, transaction, orderId, status, processDate, orderTotal, venderTotal) {
        return { userName, venderName, transaction, orderId, status, processDate, orderTotal, venderTotal };
      }
      
      const rows = [
        createData('Vender', 'Vendor B', 'Transaction 2', 'Order 2', 'Active', '2024-08-09', '$200', '$250'),
        // createData('Akash Doe', 'Vendor A', 'Transaction 1', 'Order 1', 'Active', '2024-08-08', '$100', '$150'),
        // createData('Prasad Smith', 'Vendor B', 'Transaction 2', 'Order 2', 'Pending', '2024-08-09', '$200', '$250'),
        // createData('Ankit', 'Vendor B', 'Transaction 2', 'Order 2', 'Pending', '2024-08-09', '$200', '$250'),
        
        
        
      ];
  
  return (
    <div>
         {/* <DialogContent sx={{ padding: "20px" }}> */}
      <Box sx={{marginTop:'15px',paddingLeft:'10px'}}>
          <Typography
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              gap:'15px',
              width: "20%",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={handleBack}
          >
            <img
              src={backImg}
              alt="backImg"
              style={{ height: "29px", width: "29px" }}
            />
            <Typography
              sx={{ color: "#065F46", fontWeight: "500", fontSize: "20px" }}
            >
              Back
            </Typography>
          </Typography>
        </Box>
   <Box sx={{marginTop:'40px',marginBottom:'30px'}}>
    <TableContainer component={Paper} sx={{ padding: '26px',borderRadius:'24px'}}>
      <Table sx={{ minWidth: 650 }} aria-label="custom table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: '#362B24',fontWeight:'500',fontFamily:'Heebo',fontSize:'16px' }}>User Name</TableCell>
            <TableCell sx={{ color: '#362B24',fontWeight:'500',fontFamily:'Heebo',fontSize:'16px' }}>Vender Name</TableCell>
            <TableCell sx={{ color: '#362B24',fontWeight:'500',fontFamily:'Heebo',fontSize:'16px' }}>Transaction ID</TableCell>
            <TableCell sx={{ color: '#362B24',fontWeight:'500',fontFamily:'Heebo',fontSize:'16px' }}>Order ID</TableCell>
            <TableCell sx={{ color: '#362B24',fontWeight:'500',fontFamily:'Heebo',fontSize:'16px' }}>Status</TableCell>
            <TableCell sx={{ color: '#362B24',fontWeight:'500',fontFamily:'Heebo',fontSize:'16px' }}>Processed Date</TableCell>
            <TableCell sx={{ color: '#362B24',fontWeight:'500',fontFamily:'Heebo',fontSize:'16px' }}>Order Total</TableCell>
            <TableCell sx={{ color: '#362B24',fontWeight:'500',fontFamily:'Heebo',fontSize:'16px' }}>Vender Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.userName}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{row.userName}</TableCell>
              <TableCell>{row.venderName}</TableCell>
              <TableCell>{row.transaction}</TableCell>
              <TableCell>{row.orderId}</TableCell>
              <TableCell>{row.status}</TableCell>
              <TableCell>{row.processDate}</TableCell>
              <TableCell>{row.orderTotal}</TableCell>
              <TableCell>{row.venderTotal}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Paginations/>
    </TableContainer>
  
    </Box> 



    </div>
  )
}

export default PaymentInfo